import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

export function Pagination({
  handlePageClick,
  currentPage,
  pageCount,
  className,
}) {
  return (
    <ReactPaginate
      className={`${className} flex flex-row gap-x-0.5 font-extrabold text-playmove-mediumblue`}
      breakLabel={
        <div className="border-playmove-mediumblue border-2 flex w-10 h-full text-center transition-all ease-in-out hover:bg-playmove-blue hover:!text-white">
          <span className="m-auto">...</span>
        </div>
      }
      nextLabel={
        <div className="border-playmove-mediumblue pr-1 border-2 rounded-r-full h-full w-11 flex transition-all ease-in-out hover:bg-playmove-yellow hover:!text-playmove-mediumblue">
          <FontAwesomeIcon className="m-auto" icon={faChevronRight} />
        </div>
      }
      previousLabel={
        <div className="border-playmove-mediumblue pl-1 border-2 rounded-l-full h-full w-11 flex transition-all ease-in-out hover:bg-playmove-yellow hover:!text-playmove-mediumblue">
          <FontAwesomeIcon className="m-auto" icon={faChevronLeft} />
        </div>
      }
      onPageChange={handlePageClick}
      pageRangeDisplayed={3}
      pageClassName="border-playmove-mediumblue border-2 transition-all text-xl ease-in-out hover:bg-playmove-blue hover:text-white"
      activeClassName="bg-playmove-mediumblue !text-white hover:!bg-playmove-mediumblue hover:!text-white"
      pageLabelBuilder={(page) => (
        <div className="flex w-10 h-10 text-center">
          <span className="m-auto">{page}</span>
        </div>
      )}
      marginPagesDisplayed={1}
      forcePage={currentPage}
      pageCount={pageCount}
      renderOnZeroPageCount={false}
    />
  );
}
