import { useAxiosClient } from '..';

export const useStudentApi = () => {
    const { axiosClient } = useAxiosClient();

    return {
        studentList: async (page, itemsPerPage, nameFilter, cityFilter, stateFilter, schoolFilter, classFilter) => (await axiosClient.get(`/v1/student?page=${page}&itemsPerPage=${itemsPerPage}&nameFilter=${nameFilter}&cityFilter=${cityFilter}&stateFilter=${stateFilter}&classFilter=${classFilter}&schoolFilter=${schoolFilter}`)).data,
        deleteStudent: async (id) => (await axiosClient.delete(`/v1/student/${id}`)),    
        createStudent: async (student) => (await axiosClient.post(`/v1/student`, student)),
        updateStudent: async (student) => (await axiosClient.put(`/v1/student/${student.id}`, student)),
        uploadStudent: async (file) => (await axiosClient.post(`/v1/student/upload`, file))
    }
}