import PageWithSidebar from "../PageWithSidebar";
import FilterButton from "../Shared/Buttons/FilterButton";
import CourseCard from "../Shared/CourseCard";
import SearchBar from "../Shared/SearchBar";
import {Fragment, useEffect, useState} from "react";
import {useCoursesApi, useTrailsApi} from "../../Hooks";
import {ReactComponent as SpinnerIcon} from "../../assets/img/icons/spinner.svg";
import ReactTooltip from 'react-tooltip';


export default function Cursos() {
    const [loading, setLoading] = useState(true);
    const [loadingCourses, setLoadingCourses] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState("todos");
    const [search, setSearch] = useState("");
    const [courses, setCourses] = useState([]);
    const coursesApi = useCoursesApi();
    const trailsApi = useTrailsApi();

    const switchFilter = async (filter) => {
        setLoadingCourses(true);
        await new Promise((r) => setTimeout(r, 300)); 
        setSelectedFilter(filter);        
        setLoadingCourses(false);
        setTimeout(() => {
            ReactTooltip.rebuild();
        },
        500);
    }

    useEffect(() => {
        setLoading(true);
        const loadData = async () => {            
            await new Promise((r) => setTimeout(r, 300));
            const courseList = await coursesApi.courseList(0, 0, "");
            const trailList = await trailsApi.trailList(0,0, "");
            const courses = trailList.items.concat(courseList.items);
            setCourses(courses);
            setLoading(false);
        }
        loadData();
    }, []);

    const loadCoursesOnly = async () => {
        setLoadingCourses(true);
        await new Promise((r) => setTimeout(r, 300));
        const courseList = await coursesApi.courseList(0, 0, search);
        const trailList = await trailsApi.trailList(0,0, search);
        const courses = trailList.items.concat(courseList.items);
        setCourses(courses);   
        setLoadingCourses(false);   
        
    }

    const setFavourite = (id) => {
		const course = courses.find((course) => course.id === id);
		course.favourite = !course.favourite;
		setCourses([...courses]);

		if (course.favourite) {
			if (course.type === "Trail")
				trailsApi.favouriteTrail(course.id);
			else coursesApi.favouriteCourse(id);
		} else {
			if (course.type === "Trail")
				trailsApi.unfavouriteTrail(course.id);
			else coursesApi.unfavouriteCourse(id);
		}
    };

    const courseCards = courses.filter((course) => {
        return !(selectedFilter === "favoritos" && !course.favourite)
            && !(selectedFilter === "andamento" && !((course.courseProgress?.progress >= 0 && course.courseProgress?.progress < 100) || (course.trailProgress?.progress >= 0 && course.trailProgress?.progress < 100)))
            && !(selectedFilter === "concluidos" && !(course.courseProgress?.completed || course.trailProgress?.completed));
    }).map((course) =>
        (<Fragment key={course.id}>
            <CourseCard course={course} 
                        setFavourite={() => setFavourite(course.id)}/>
        </Fragment>)
    );

    const submitSearch = () => {
        loadCoursesOnly();
    }


    return (
        <PageWithSidebar loading={loading}>
            <div className="flex flex-col gap-y-5">
                <div
                    className="flex h-12 md:h-24 text-2xl border-b-gray-300 border-b-[3px]">
                    <b className="">Nossos cursos</b>
                </div>
                <div
                    className="flex flex-wrap justify-center md:justify-start md:flex-row gap-y-5 gap-x-5 mb-auto">
                    <FilterButton text="todos" selected={selectedFilter === "todos"}
                                  onClick={() => switchFilter("todos")}/>
                    <FilterButton text="em andamento" selected={selectedFilter === "andamento"}
                                  onClick={() => switchFilter("andamento")}/>
                    <FilterButton text="concluídos" selected={selectedFilter === "concluidos"}
                                  onClick={() => switchFilter("concluidos")}/>
                    <FilterButton text="favoritos" selected={selectedFilter === "favoritos"}
                                  onClick={() => switchFilter("favoritos")}/>
                    <SearchBar value={search} onChangeText={(e) => setSearch(e.target.value)} onClick={submitSearch}
                               placeholder="Buscar por título..." className="md:ml-auto"/>
                </div>
            </div>
            <div className="pt-10 flex flex-wrap gap-x-10 gap-y-20 mb-10 justify-center lg:justify-start">
            <ReactTooltip className="!rounded-xl"/>  
                {loadingCourses ? (<SpinnerIcon className="mt-32 mx-auto"/>) : courseCards}
            </div>
        </PageWithSidebar>
    );
}
