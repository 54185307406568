const ExportableTable = ({ data, columns, fileName }) => {
    const exportToExcel = () => {
      const table = document.getElementById("export-table");
      const html = `
        <html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
          <head>
            <meta charset="UTF-8">
            <!-- Define que o arquivo é um documento Excel -->
            <xml>
              <x:ExcelWorkbook>
                <x:ExcelWorksheets>
                  <x:ExcelWorksheet>
                    <x:Name>Planilha</x:Name>
                    <x:WorksheetOptions>
                      <x:DisplayGridlines/>
                    </x:WorksheetOptions>
                  </x:ExcelWorksheet>
                </x:ExcelWorksheets>
              </x:ExcelWorkbook>
            </xml>
          </head>
          <body>
            ${table.outerHTML}
          </body>
        </html>
      `;
  
      const blob = new Blob([`\ufeff${html}`], {
        type: "application/vnd.ms-excel;charset=UTF-16LE",
      });
  
      const url = URL.createObjectURL(blob);
  
      const date = new Date();
      const formattedDate = `${date.getDate()
        .toString()
        .padStart(2, "0")}_${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}_${date.getFullYear()}`;
      const file = `${fileName || "export"}_${formattedDate}.xls`;
  
      // Baixa o arquivo
      const a = document.createElement("a");
      a.href = url;
      a.download = file;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
  
    return (
      <div>
        <button
          onClick={exportToExcel}
          className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
        >
          Exportar para Excel
        </button>
        <table
          id="export-table"
          border="1"
          style={{ display: "none" }}
        >
          <thead>
            <tr>
              {columns.map((col) => (
                <th key={col.accessor}>{col.Header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((col) => (
                  <td key={col.accessor}>{row[col.accessor] || "0%"}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  
  export default ExportableTable;
  