import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faWarning} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';

export default function Error({type}) {
    const errorMessage = type === "404" ? "erro 404 - recurso não encontrado" : "erro interno";
    const navigate = useNavigate();

    return (
        <main className="flex flex-col h-screen">
            <div className="m-auto flex flex-col text-center gap-5 justify-center cursor-pointer" onClick={() => navigate('/')}>
                <FontAwesomeIcon className="h-32 w-32 p-2 self-center" color="#042ad8" icon={faWarning}/>
                <b className="text-2xl">{errorMessage}</b>
            </div>
        </main>
    );
}
