import { useAxiosClient } from '..';

export const useSchoolApi = () => {
    const { axiosClient } = useAxiosClient();

    return {
        schoolList: async (page, itemsPerPage, nameFilter, cityFilter, stateFilter) => (await axiosClient.get(`/v1/school?page=${page}&itemsPerPage=${itemsPerPage}&nameFilter=${nameFilter}&cityFilter=${cityFilter}&stateFilter=${stateFilter}`)).data,
        deleteSchool: async (id) => (await axiosClient.delete(`/v1/school/${id}`)),    
        createSchool: async (school) => (await axiosClient.post(`/v1/school`, school)),
        updateSchool: async (school) => (await axiosClient.put(`/v1/school/${school.id}`, school)),
    }
}