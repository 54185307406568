import axios from 'axios';
import {apiUrl} from "../../Configs";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

const baseURL = apiUrl;
const logoutUrl = "/logout";
const error404Url = "/error404";
const error500Url = "/error500";

export const useAxiosClient = () => {
    const navigate = useNavigate();

    const axiosClient = axios.create({
        baseURL
    });

    const axiosClientNoIntercept = axios.create({
        baseURL
    });

    const axiosClientNoResponseIntercept = axios.create({
        baseURL
    });


    useEffect(() => {
        axiosClient.interceptors.request.use(function (config) {
            const token = localStorage.getItem("token");
            if (token && config.headers.Authorization === undefined) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        });

        axiosClientNoResponseIntercept.interceptors.request.use(function (config) {
            const token = localStorage.getItem("token");
            if (token && config.headers.Authorization === undefined) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        });


        axiosClient.interceptors.response.use(
            response => {
                return response;
            },
            async function (error) {
                if (!error.response || error.code === 'ERR_NETWORK')
                    navigate(error500Url);

                switch (error.response.status) {
                    case 401:
                        const originalConfig = error.config;
                        if (!originalConfig._retry || originalConfig.url.includes("refresh")) {
                            try {
                                originalConfig._retry = true;
                                const token = localStorage.getItem("token");
                                const refreshToken = localStorage.getItem("refreshToken");
                                if (!token || !refreshToken) {
                                    throw new Error('No token found');
                                }

                                const newToken = await axios.post(`${baseURL}/v1/token/refresh`, {
                                    token: token,
                                    refreshToken:refreshToken
                                }).then(res => res.data);
                                
                                localStorage.setItem("token", newToken.token);
                                localStorage.setItem("refreshToken", newToken.refreshToken);

                                originalConfig.headers.Authorization = `Bearer ${newToken.token}`;

                                return await axiosClient.request(originalConfig);
                            } catch (e) {
                                localStorage.removeItem("token");
                                localStorage.removeItem("refreshToken");
                                navigate(logoutUrl);
                            }
                        } else {
                            localStorage.removeItem("token");
                            localStorage.removeItem("refreshToken");
                            navigate(logoutUrl);
                        }

                        break;

                    case 500:
                        navigate(error500Url);
                        break;

                    case 404:
                        navigate(error404Url);
                        break;

                    default:
                        return error;
                }

            }
        );
    }, [axiosClient, axiosClientNoResponseIntercept, navigate]);

    return {axiosClient, axiosClientNoIntercept, axiosClientNoResponseIntercept};
}