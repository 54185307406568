import PageWithSidebar from "../PageWithSidebar";
import {ReactComponent as UnderConstructionIcon} from "../../assets/img/icons/under-construction.svg";
import {useState, useEffect} from "react";

export default function Jogos() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const loadData = async () => {
            await new Promise((r) => setTimeout(r, 500));

            setLoading(false);
        };

        loadData();
    }, []);


    return (
        <PageWithSidebar loading={loading} additionalClasses="flex flex-col">
            <div className="m-auto flex flex-col text-center gap-5">
                <UnderConstructionIcon className="h-32 fill-playmove-blue"/>
                <b>em construção</b>
            </div>
        </PageWithSidebar>
    );
}
