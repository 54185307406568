import { ReactComponent as TrashIcon } from "../../../../assets/img/icons/trash.svg";

export default function DeleteItemButton({ onClick, className }) {
  return (
    <button className={`${className} flex flex-row`} onClick={onClick}>
      <TrashIcon className="self-center w-6 h-6" />{" "}
      <span className="text-base self-center">excluir</span>
    </button>
  );
}
