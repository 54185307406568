import { useAxiosClient } from '../';

export const useUserApi = () => {
    const { axiosClient , axiosClientNoIntercept} = useAxiosClient();

    return {
        loggedUser: async () => (await axiosClient.get(`/v1/user/logged`)).data,
        updateUserInfo: async (userInfoForm) => await axiosClient.post(`/v1/user/edit-info`, userInfoForm, {headers : {'Content-Type': 'multipart/form-data'}} ).then(res => res).catch(err => err),
        updateUserPassword: async (email, currentPassword, newPassword) => await axiosClientNoIntercept.post(`/v1/user/update-password`, {email, currentPassword, newPassword}).then(res => res).catch(err => err),
        updateUserPasswordByToken: async (forgotPasswordToken, newPassword) => await axiosClientNoIntercept.post(`/v1/user/update-password`, {forgotPasswordToken, newPassword}).then(res => res).catch(err => err),
        forgotPassword: async (email) => await axiosClientNoIntercept.post(`/v1/user/forgot-password`, {email}).then(res => res).catch(err => err),
    }
}