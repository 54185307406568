import AvatarPlaceholder from "../../../assets/img/avatar_placeholder.png";
import {ReactComponent as ChevronUpIcon} from "../../../assets/img/icons/chevron-up.svg";
import {ReactComponent as ChevronDownIcon} from "../../../assets/img/icons/chevron-down.svg";
import {Link} from "react-router-dom";
import {ReactComponent as ConfiguracoesIcon} from "../../../assets/img/icons/config.svg";
import {useState, useEffect, useContext} from "react";
import {storageUrl} from "../../../Configs";
import {UserInfoContext} from "../../../Contexts/UserInfoContext";
import { useUserApi, useLogin } from "../../../Hooks";

export default function UserInfo() {
    const [userDropdownActive, setUserDropdownActive] = useState(false);
    const [userInfo, setUserInfo] = useContext(UserInfoContext);
    const userApi = useUserApi();
    const { getRoleFromToken } = useLogin();
    const avatarUrl = userInfo.avatarPath ? `${storageUrl}/${userInfo.avatarPath}` : AvatarPlaceholder;

    useEffect(() => {
        if(userInfo.name === "") {
            const loadUserInfo = async () => {
                var loggedUserInfo = await userApi.loggedUser();
                const role = getRoleFromToken();
                loggedUserInfo = {...loggedUserInfo, role};
                setUserInfo(loggedUserInfo);
            }
            loadUserInfo();
        }
    }, [userInfo]);

    const Chevron = () => {
        return userDropdownActive ? (
            <ChevronUpIcon className="h-5 w-5 "/>
        ) : (
            <ChevronDownIcon className="h-5 w-5 "/>
        );
    };

    const UserDropdown = () => {
        return (
            <div
                className={
                    "flex flex-col pt-2  w-full text-sm text-playmove-blue transition-all duration-200 ease-in-out " +
                    (!userDropdownActive ? "invisible opacity-0" : "opacity-100")
                }
            >
                <div className="bg-playmove-lightblue w-full left-0 absolute flex flex-col items-center">
                    <Link to={`/editar-cadastro`}>
                        <div
                            className="flex flex-row items-center gap-2 pt-3 pb-3 stroke-playmove-blue fill-playmove-blue transition duration-200 ease-out hover:ease-in hover:stroke-playmove-yellow hover:fill-playmove-yellow  cursor-pointer">
                            <ConfiguracoesIcon className="fill-inherit h-5 w-5 "/>
                            <p className=" h-15 w-15 font-bold cursor-pointer">editar cadastro</p>
                        </div>
                    </Link>
                </div>
            </div>
        );
    };

    return (<div className="flex flex-col justify-center text-white pb-4 text-center w-full">
        <div
            className="rounded-full h-12 w-12 border-2 self-center bg-cover"
            style={{backgroundImage: `url(${avatarUrl})`}}
        />
        <div
            aria-label="Menu de Usuário"
            className="pt-1 w-full flex flex-row justify-center gap-x-1 stroke-white cursor-pointer transition duration-200 ease-out hover:ease-in h-15 w-15  hover:text-playmove-yellow hover:stroke-playmove-yellow"
            onClick={() => setUserDropdownActive(!userDropdownActive)}
        >
            <p className="text-sm my-auto overflow-hidden w-16 text-ellipsis">
                {userInfo.name.split(' ')[0]}
            </p>
            {Chevron()}
        </div>
       {UserDropdown()}
        <div className="border-b left-0 border-white w-auto -mx-5"></div>
    </div>);
}