import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { useTokenApi } from "./Api/useTokenApi";
import {UserInfoContext} from "../Contexts/UserInfoContext";
import AvatarPlaceholder from "../assets/img/avatar_placeholder.png";

export const useLogin = () => {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useContext(UserInfoContext);
    const tokenApi = useTokenApi();

    const login = async (email, password) => {
        const login = await tokenApi.login(email, password);
        const loginResult = {success: false, shouldUpdatePassword: false};

        if (login.status === 200) {
            const token = login.data;
            localStorage.setItem("token", token.token);
            localStorage.setItem('refreshToken', token.refreshToken);
            loginResult.success = true;
            loginResult.shouldUpdatePassword = token.shouldUpdatePassword;
            setUserInfo({name: "", id: "", avatar: AvatarPlaceholder});
            return loginResult;
        }

        return loginResult;
    };

    const logout = () => {
        clearTokens();
        navigate("/login");
    };

    const tokenExists = () => localStorage.getItem("token") && localStorage.getItem("refreshToken");

    const refreshToken = async (logoutIfFail) => {        
        const token = localStorage.getItem("token");
        const refreshToken = localStorage.getItem("refreshToken");
        const refresh = await tokenApi.refresh(token, refreshToken);
        if (refresh.status === 200) {
            const token = refresh.data;
            localStorage.setItem("token", token.token);
            localStorage.setItem('refreshToken', token.refreshToken);
            return true;
        }
        else if(logoutIfFail) {            
            logout();
        }

        return false;
    }

    const clearTokens = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
    }

    const tokenExpiration = () => {
        const token = localStorage.getItem("token");
        return JSON.parse(atob(token.split('.')[1])).exp;
    }

    const checkIfTokenExpired = async () => {
        const token = localStorage.getItem("token");
        if(!token) {
            logout();
            return true;
        }

        const tokenExpired = tokenExpiration < Date.now() / 1000;
        if(tokenExpired && !await refreshToken()) {
            logout();
            return true;
        }

        return false;
    }

    const getRoleFromToken = () => {
        const token = localStorage.getItem("token");
        return JSON.parse(atob(token.split('.')[1])).role;
    }


    return {        
        tokenExpiration,
        getRoleFromToken,
        checkIfTokenExpired,
        refreshToken,
        tokenExists,
        clearTokens,
        login,
        logout
    };
}