import { useAxiosClient } from '../';

export const useCoursesApi = () => {
    const { axiosClient, axiosClientNoResponseIntercept } = useAxiosClient(true);

    return {
        courseList: async (page, itemsPerPage, titleFilter) => (await axiosClient.get(`/v1/course/active?page=${page}&itemsPerPage=${itemsPerPage}&withTitle=${titleFilter}`)).data,
        fullCourse: async (id) => (await axiosClient.get(`/v1/course/${id}/full`)).data,
        course: async (id) => (await axiosClient.get(`/v1/course/${id}`)).data,
        updateLessonProgressPassive: async (data) => (await axiosClientNoResponseIntercept.post(`/v1/course/${data.courseId}/progress`, data)),
        updateLessonProgress: async (data) => (await axiosClient.post(`/v1/course/${data.courseId}/progress`, data)),
        resetLessonProgresses: async (data) => (await axiosClient.post(`/v1/course/${data.courseId}/progress/reset`, data)),
        getCertificateInfo: async (id) => (await axiosClient.get(`/v1/course/${id}/certificate`)).data,
        favouriteCourse: async (id) => (await axiosClientNoResponseIntercept.post(`/v1/course/${id}/favourite`)),
        unfavouriteCourse: async (id) => (await axiosClientNoResponseIntercept.post(`/v1/course/${id}/unfavourite`)),
        rateCourse: async (data) => (await axiosClient.post(`/v1/course/${data.courseId}/rating`, data)),
        rateModule: async (data) => (await axiosClientNoResponseIntercept.post(`/v1/module/${data.moduleId}/rating`, data)),
    }
}