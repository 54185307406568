import PageWithSidebar from "../PageWithSidebar";
import {useContext, useEffect, useRef, useState} from "react";
import {UserInfoContext} from "../../Contexts/UserInfoContext";
import TextInput from "../Shared/TextInput";
import SaveButton from "../Shared/Buttons/SaveButton";
import {useUserApi} from "../../Hooks";
import InlineAlert from "../Shared/InlineAlert";
import {isNullOrWhitespace} from "../../Utils/TextUtils";
import {storageUrl} from "../../Configs";
import AvatarPlaceholder from "../../assets/img/avatar_placeholder.png";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPencil, faXmark, faRotateLeft} from '@fortawesome/free-solid-svg-icons'
import ChangePasswordButton from "../Shared/Buttons/ChangePasswordButton";
import ChangePasswordModal from "./ChangePasswordModal";

export default function EditarCadastro() {
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState({});
    const [userInfo, setUserInfo] = useContext(UserInfoContext);
    const [editingUserInfo, setEditingUserInfo] = useState({});
    const userApi = useUserApi();
    const [tempAvatarUrl, setTempAvatarUrl] = useState("");
    const [removeAvatar, setRemoveAvatar] = useState(false);
    const [newAvatarFile, setNewAvatarFile] = useState(null);
    const imageInputRef = useRef(null);
    const avatarUrl = tempAvatarUrl ? tempAvatarUrl : editingUserInfo.avatarPath ? `${storageUrl}/${editingUserInfo.avatarPath}` : AvatarPlaceholder;
    const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

    useEffect(() => {
        setLoading(true);
        const loadData = async () => {
            await new Promise((r) => setTimeout(r, 500));

            setEditingUserInfo(userInfo);
            setLoading(false);
        };

        loadData();
    }, [userInfo]);

    const prepareFormForRequest = () => {
        const form = new FormData();
        form.append('id', editingUserInfo.id);
        form.append('name', editingUserInfo.name);
        form.append('email', editingUserInfo.email);

        if (removeAvatar)
            form.append('removeAvatar', removeAvatar);

        if (newAvatarFile)
            form.append('avatar', newAvatarFile);

        return form;
    }

    const handleAvatarClick = (e) => {
        imageInputRef.current.click();
    };

    const handleAvatarChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewAvatarFile(file);
            setTempAvatarUrl(URL.createObjectURL(file));
        }
    }

    const handleSubmitUserInfo = async (e) => {
        e.preventDefault();

        if (isNullOrWhitespace(editingUserInfo.name) || isNullOrWhitespace(editingUserInfo.email)) {
            setAlert({type: "error", message: "Os campos nome e e-mail não podem ser vazios"});
            return;
        }

        setLoading(true);
        setAlert({});
        await new Promise((r) => setTimeout(r, 500));

        try {
            const result = await userApi.updateUserInfo(prepareFormForRequest());
            if (result.status === 200) {
                setNewAvatarFile(null);
                setRemoveAvatar(false);
                var editedUserInfo = result.data;
                var loggedUserInfo = {...userInfo, ...editedUserInfo};
                setUserInfo(loggedUserInfo);
                setAlert({type: "success", message: "Dados atualizados com sucesso!"});
            } else {
                if (result.response.data.some(e => e === "E-mail já está sendo utilizado"))
                    setAlert({type: "error", message: "Este e-mail já está sendo utilizado!"});
                else
                    setAlert({
                        type: "error",
                        message: "Não foi possível atualizar seu cadastro. Tente novamente mais tarde"
                    });
            }
        } catch (e) {
            setAlert({type: "error", message: "Não foi possível atualizar seu cadastro. Tente novamente mais tarde"});
        }

        setLoading(false);
    }

    const handleRemoveAvatar = () => {
        setRemoveAvatar(true);
        setTempAvatarUrl(AvatarPlaceholder);
    }


    const handleUndoAvatar = () => {
        if(removeAvatar)
            setRemoveAvatar(false);

        if(newAvatarFile)
            setNewAvatarFile(null);

        setTempAvatarUrl("");
    }

    const RemoveAvatarButton = () => {
        return (<span className="ml-auto">
                                <FontAwesomeIcon
                                    className="peer text-playmove-red ml-auto cursor-pointer transition-all duration-200 ease-in-out hover:scale-125"
                                    icon={faXmark}
                                    onClick={handleRemoveAvatar}/>
                                <FontAwesomeIcon
                                    className="absolute left-0 top-0 bottom-0 right-0 m-auto h-12 transition-all ease-in-out pointer-events-none duration-200 text-black opacity-0 can-hover:peer-hover:opacity-50 peer-hover:scale-105"
                                    icon={faXmark}/>
                                 </span>);
    }

    const UndoAvatarButton = () => {
        return (<span className="ml-auto">
                                <FontAwesomeIcon
                                    className="peer text-playmove-red ml-auto cursor-pointer transition-all duration-200 ease-in-out hover:scale-125"
                                    icon={faRotateLeft}
                                    onClick={handleUndoAvatar}/>
                                <FontAwesomeIcon
                                    className="absolute left-0 top-0 bottom-0 right-0 m-auto h-12 transition-all ease-in-out pointer-events-none duration-200 text-black opacity-0 can-hover:peer-hover:opacity-50 peer-hover:scale-105"
                                    icon={faRotateLeft}/>
                                 </span>);
    }

    return (
        <PageWithSidebar loading={loading} additionalClasses="flex flex-col gap-y-8">
            <ChangePasswordModal email={userInfo.email} isOpen={changePasswordModalOpen} onClose={() => setChangePasswordModalOpen(false)} onSuccess={() => setAlert({type: "success", message: "Senha atualizada com sucesso!"})} />
            <div
                className="flex h-12 md:h-24 text-2xl border-b-gray-300 border-b-[3px]">
                <b className="">Editar cadastro</b>
            </div>

            <div className="flex flex-col gap-y-8">
                <div className="flex flex-col-reverse w-full md:flex-row gap-x-8 gap-y-3">
                    <div className="flex flex-col w-full md:w-96">

                        <InlineAlert alert={alert} visible={alert.type === "error" || alert.type === "success"}/>

                        <TextInput id="nome" label="Nome" placeholder="seu nome" type="text"
                                   value={editingUserInfo.name}
                                   onChangeText={(e) => setEditingUserInfo({...editingUserInfo, name: e.target.value})}
                                   onClick={() => setAlert({})}
                                   error={alert.type === "error"} size="sm"/>


                        <TextInput id="email" label="E-mail" placeholder="seu e-mail" type="text"
                                   value={editingUserInfo.email}
                                   onChangeText={(e) => setEditingUserInfo({...editingUserInfo, email: e.target.value})}
                                   onClick={() => setAlert({})}
                                   error={alert.type === "error"} className="mt-5" size="sm"/>
                        <div className="mt-5 ml-auto flex flex-row gap-x-3">
                        </div>


                    </div>

                    <div className="flex flex-col rounded-lg h-56 py-6 w-full md:w-56 bg-playmove-lightblue">
                        <div
                            className="relative h-32 w-32 flex self-center">
                            <input type="file" ref={imageInputRef} className="hidden" onChange={handleAvatarChange}
                                   accept=".png, .jpeg, .jpg"/>
                            <div
                                className="peer absolute h-full w-full group rounded-full cursor-pointer bg-cover transition-all duration-200 ease-in-out hover:scale-105"
                                onClick={handleAvatarClick}
                                style={{backgroundImage: `url(${avatarUrl})`}}></div>
                            <FontAwesomeIcon
                                className="absolute left-0 top-0 bottom-0 right-0 m-auto h-12 transition-all ease-in-out pointer-events-none duration-200 text-black opacity-0 can-hover:peer-hover:opacity-50 peer-hover:scale-105"
                                icon={faPencil}/>
                            {removeAvatar || newAvatarFile ? UndoAvatarButton() : RemoveAvatarButton()}

                        </div>
                        <p className="text-center font-bold mt-auto">Clique na foto para editar</p>
                    </div>
                </div>
                <div className="flex flex-row gap-x-3">


                    <ChangePasswordButton onClick={() => setChangePasswordModalOpen(true)}/>
                <SaveButton onClick={handleSubmitUserInfo}/>
                </div>
            </div>

        </PageWithSidebar>
    );
}
