import { storageUrl } from "../../../Configs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartRegular } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import { truncate } from "../../../Utils/TextUtils";
import TrailCardHeader from "../../../assets/img/trail-card.png";
import ReactTooltip from 'react-tooltip';

export default function CourseCard({ course, setFavourite }) {
	const imageUrl = `${storageUrl}/${course.imagePath}`;
	const isTrail = course.type === "Trail";
	const progressData = isTrail ? course.trailProgress : course.courseProgress;
	const progress = progressData?.progress ?? 0;

	const link = isTrail ? `/trilhas/${course.id}` : `/cursos/${course.id}`;
	const navigate = useNavigate();

	const handleCardClick = () => {
		navigate(link);
	};

	const handleHeartClick = (e) => {
		e.stopPropagation();
		setFavourite();
	};

	return (        
	<div className="grid grid-rows-2 h-[20rem] text-playmove-darkgray w-[18rem] max-w-[18rem] transition hover:ease-in-out hover:scale-105">
			  <div
				aria-label={`Acessar curso ${course.title}`}
				onClick={handleCardClick}
				className="relative flex cursor-pointer rounded-t-xl bg-cover "
				style={{ backgroundImage: `url('${imageUrl}')` }}>
				{isTrail && (
					<img
						src={TrailCardHeader}
						alt="Trail Card Header"
						className="absolute inset-0 h-[4.3rem]"
					/>
				)}
				<FontAwesomeIcon
					className="h-5 w-5 p-2 ml-auto mr-2 transition hover:ease-in-out hover:scale-125"
					onClick={handleHeartClick}
					color="white"
					icon={
						course.favourite
							? faHeartSolid
							: faHeartRegular
					}
				/>

				<div className="absolute overflow-x-hidden -bottom-1 w-full h-1.5">
					<div
						className={`bg-playmove-green h-1.5 -bottom-0.5 ${progress < 100 && 'rounded-r-full'}`}
                        data-tip={`${Math.round(progress)}% ${isTrail ? 'da trilha concluída' : 'do curso concluído'}`}
                        data-type="light"
						style={{ width: `${progress}%` }}></div>
				</div>
			</div>
			<div
				className={`${
					isTrail
						? "bg-playmove-lightyellow"
						: "bg-playmove-lightblue"
				} rounded-b-xl px-5 py-3 h-[13rem]`}>
				<p className="font-dosisbold text-[1.3rem]">
					{truncate(course.title, 45)}
				</p>
				<p className="font-inter pt-2 pb-2 text-sm leading-5">
					{truncate(course.description, 150)}
				</p>
			</div>
		</div>
	);
}
