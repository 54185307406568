import tw, { styled } from 'twin.macro'
import Select, { components } from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCaretDown} from '@fortawesome/free-solid-svg-icons'

const StyledReactSelect = styled(Select)`
 .Select__control{
  ${(({error}) => error ? tw`border-playmove-red bg-playmove-lightred hover:border-playmove-red` : tw`border-playmove-mediumblue hover:border-playmove-mediumblue` ) }

   ${tw`text-sm border min-h-full rounded-tl-md rounded-tr-xl rounded-bl-xl rounded-br-3xl shadow-none`}
 }

 .Select__input{
  ${tw`border-none ring-0`}
  }  

  .Select__indicator-separator{
  ${tw`hidden`}
  }

  .Select__indicator{
  ${tw`!text-black`}
  }  

  .Select__clear-indicator{
  ${tw`cursor-pointer`}
  }

  .Select__menu-list{
    ${tw`max-h-36`}
    }

    .Select__menu{
      ${tw`rounded-md mt-1`}
      }
  
`;

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props} className="cursor-pointer">
      <FontAwesomeIcon className="scale-x-50 " icon={faCaretDown} />
    </components.DropdownIndicator>
  );
};

export default function DropdownInput({ disabled, placeholder, error, label, items, onChange, value, emptyMessage, className, inputClassName}){

    return (<div className={`${className} flex flex-col`}>
      <label className={`font-dosisbold mb-1 text-sm`}>{label}</label>
        <StyledReactSelect
            options={items}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            components={{ DropdownIndicator}}             
            isSearchable
            className={inputClassName}         
            classNamePrefix={'Select'}
            error={error}
            isDisabled={disabled}
            noOptionsMessage={() =>emptyMessage && items.length === 0 ? emptyMessage : "Nenhum item encontrado"}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: '#DEEBF7',
                primary: '#0080FB',
              },
            })}
          />
          </div>
      )
}