import { ReactComponent as PlusIcon } from "../../../../assets/img/icons/plus.svg";

export default function AddItemButton({ onClick, className }) {
  return (
    <button className={`${className} flex flex-row gap-x-1`} onClick={onClick}>
      <PlusIcon className="self-center w-6 h-6" />
      <span className="text-base self-center">adicionar</span>
    </button>
  );
}
