import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'

import {ReactComponent as BadIcon} from '../../../assets/img/icons/rating/bad2.svg';
import {ReactComponent as AverageIcon} from '../../../assets/img/icons/rating/average2.svg';
import {ReactComponent as GoodIcon} from '../../../assets/img/icons/rating/good2.svg';
import SendButton from '../Buttons/SendButton';
import { useCoursesApi } from '../../../Hooks/Api/useCoursesApi';

function RateButton({selected, onClick, rating}) {
  return (
    <button
      className={`place-content-center flex flex-col w-[7rem] h-[9rem] rounded-[0.625rem] border hover:border-playmove-mediumblue ${selected ? 'border-playmove-mediumblue' : 'border-playmove-lightgray'}`}
      onClick={onClick}
    >        
      <rating.icon className={`w-20 h-20 mx-auto ${rating.color}`}/>
      <span className="md:my-0 mt-2 font-bold text-playmove-darkgray mx-auto">{rating.name}</span>
    </button>
  );
}

const ratings = [
    {name: "Ruim", icon: BadIcon, color:"fill-playmove-red"},
    {name: "Médio", icon: AverageIcon, color:"fill-playmove-orange"},
    {name: "Gostei", icon: GoodIcon, color:"fill-playmove-greencheck"}
];

export default function RateModuleModal({ isOpen, onClose, moduleId }) {
    
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [selectedRating, setSelectedRating] = useState(0);
    const coursesApi = useCoursesApi();	

    const handleSubmitRating = async () => {
		const data = {
			moduleId,
			rating: selectedRating,
		};

		coursesApi.rateModule(data);

		onClose();
    };

    useEffect(() => {
        setSelectedRating(0);
        setButtonEnabled(false);
    }, [moduleId]);
    
    return (
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => null}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className={`flex min-h-full items-center justify-center p-4 text-center`}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full flex flex-col max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg text-center leading-6 font-dosisbold"
                                    >
                                        O que você achou desse módulo?
                                    </Dialog.Title><div className="flex flex-col">
                                    <div className="flex flex-row gap-x-4 justify-center mt-5">
        {ratings.map((rating, index) => (
            <RateButton
                key={rating.name}
                selected={selectedRating === index + 2}
                onClick={() => {
                    setSelectedRating(index + 2);
                    setButtonEnabled(true);
                }   
                }
                rating={rating}
            />
        ))}  </div>                             
        </div>    
        <SendButton enabled={buttonEnabled} onClick={handleSubmitRating} className="mt-8 mx-auto" />
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>

                    </div>
                </Dialog>
            </Transition>
    )
}