import { useEffect} from "react";
import {ReactComponent as SpinnerIcon} from "../../assets/img/icons/spinner.svg";
import { useLogin } from "../../Hooks";

export default function Logout() {
    const {logout} = useLogin();

    useEffect(() => {
         setTimeout(logout, 500);             
    }, []);

    return (
        <main className="flex flex-col h-screen">
            <div className="m-auto flex flex-col text-center gap-5 justify-center">
                <SpinnerIcon className="self-center"/>
            </div>
        </main>
    );
}
