import { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LoginBackground from "../../assets/img/login-bg.png";
import { passwordRegex } from "../../Configs";
import { useUserApi } from "../../Hooks";
import { ReactComponent as DenteDeLeaoIcon } from "../../assets/img/icons/dente-de-leao.svg";
import { ReactComponent as PlaymoveAcademiaIcon } from "../../assets/img/icons/playmove-academia.svg";
import TextInput from "../Shared/TextInput";
const ticksToDate = require("ticks-to-date");

export default function NovaSenha() {
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState({
    confirmation: false,
    regex: false,
    invalid: false,
  });
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const { forgotPasswordToken } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [expired, setExpired] = useState(false);
  const navigate = useNavigate();
  const userApi = useUserApi();

  useEffect(() => {
    const expiration = searchParams.get("expiration");
    if (!expiration) {
      setExpired(true);
      return;
    }

    const expirationInTicks = parseInt(expiration);
    const expirationInDate = ticksToDate(expirationInTicks);
    const now = new Date();
    if (now > expirationInDate) {
      setExpired(true);
    }
  }, [searchParams]);

  const handleSubmitNewPassword = async () => {
    if(expired)
        return;

    setPasswordError({ confirmation: false, regex: false, invalid: false });
    var tempPasswordError = {
      confirmation: false,
      regex: false,
      invalid: false,
    };
    setLoading(true);
    await new Promise((r) => setTimeout(r, 500));

    if (!passwordRegex.test(newPassword))
      tempPasswordError = { ...tempPasswordError, regex: true };
    else if (newPassword !== newPasswordConfirmation)
      tempPasswordError = { ...tempPasswordError, confirmation: true };

    if (!tempPasswordError.regex && !tempPasswordError.confirmation) {
      const updatePasswordResult = await userApi.updateUserPasswordByToken(
        forgotPasswordToken,
        newPassword
      );
      if (updatePasswordResult.status === 200) {
        navigate("/login?senhaAtualizada=true");
      } else tempPasswordError = { ...tempPasswordError, invalid: true };
    }

    setPasswordError(tempPasswordError);
    setLoading(false);
  };

  const PlaymoveAcademiaLogo = () => {
    return (
      <div
        className="flex flex-row cursor-pointer"
        onClick={() => navigate("/")}
      >
        <div className="self-center pr-3">
          <PlaymoveAcademiaIcon className="fill-playmove-blue h-14 w-14" />
        </div>
        <div className="flex flex-col -mb-2">
          <b className="mt-auto -mb-2 mr-auto text-playmove-gray font-dosisbold text-sm">
            playmove
          </b>
          <b className="mt-automr-auto text-playmove-blue font-dosisbold text-[1.75rem]">
            ACADEMIA
          </b>
        </div>
      </div>
    );
  };

  const PlaymoveFooterLogo = () => {
    return (
      <div className="m-auto flex flex-row items-center">
        <DenteDeLeaoIcon className="h-[20px] fill-white" />{" "}
        <p className="ml-1 text-white tracking-wider font-bold text-lg">
          playmove
        </p>
      </div>
    );
  };

  const UpdatePasswordForm = () => {
    return (
      <div>
        <div
          className={`text-center text-sm rounded-lg mt-2 mb-2 font-bold py-1 transition-opacity duration-200 ease-in-out`}
        >
          Crie sua nova senha
        </div>

        {expired ? (
          <div
            className={`text-center text-sm border-l-2 rounded-lg mb-2 px-3 font-bold py-1 transition-colors duration-200 ease-in-out bg-playmove-lightred border-playmove-red`}
          >
            Link para redefinir senha expirado
          </div>
        ) : (
          <div
            className={`text-center text-sm border-l-2 rounded-lg mb-2 px-3 font-bold py-1 transition-colors duration-200 ease-in-out ${
              passwordError.regex
                ? "bg-playmove-lightred border-playmove-red"
                : "bg-green-100 border-green-500"
            }`}
          >
            A senha nova deve conter no mínimo 6 caracteres,
            <br /> uma letra e um número.
          </div>
        )}
        <TextInput
          id="newPassword"
          label="Nova senha"
          placeholder=""
          type="password"
          disabled={expired}
          value={newPassword}
          onChangeText={(e) => setNewPassword(e.target.value)}
          onClick={() =>
            setPasswordError({ confirmation: false, regex: false })
          }
          error={passwordError.confirmation || passwordError.regex}
          className="mt-1"
          size="sm"
        />
        <TextInput
          id="newPasswordConfirmation"
          label="Confirme a nova senha"
          placeholder=""
          type="password"
          value={newPasswordConfirmation}
          disabled={expired}
          onChangeText={(e) => setNewPasswordConfirmation(e.target.value)}
          onClick={() =>
            setPasswordError({ confirmation: false, regex: false })
          }
          error={passwordError.confirmation || passwordError.regex}
          className="mt-5"
          size="sm"
        />

        <button
          onClick={handleSubmitNewPassword}
          type="submit"
          className={`mt-5 w-full bg-playmove-green rounded-xl p-2 text-lg font-dosisbold text-playmove-blue transition-all ease-in-out border-playmove-darkgreen border-b-[3px] ${!expired && 'hover:bg-playmove-yellow'} ${
            loading && "cursor-wait"
          }`}
        >
          Confirmar
        </button>

        <div
          className={`mt-3 text-center bg-playmove-lightred border-l-2 border-playmove-red rounded-lg mb-2 font-bold py-1 transition-all duration-200 ease-in-out ${
            passwordError.confirmation || passwordError.invalid
              ? "opacity-100"
              : "invisible opacity-0"
          }`}
        >
          {passwordError.invalid
            ? "Esta redefinição de senha expirou!"
            : "As senhas não coincidem!"}
        </div>
      </div>
    );
  };

  return (
    <main
      className={`bg-cover bg-fixed bg-center w-screen h-screen overflow-x-hidden pt-10 lg:pt-20 px-6 lg:px-24`}
      style={{ backgroundImage: `url(${LoginBackground})` }}
    >
      <div
        className={`flex flex-col min-w-fit lg:w-[40%] h-[100%] ${
          loading ? "cursor-wait opacity-50" : "opacity-100"
        }`}
      >
        <div className="bg-white h-[80%] overflow-y-auto md:overflow-y-hidden rounded-tl-lg rounded-tr-3xl pt-12 px-12 lg:px-24 flex flex-col">
          <PlaymoveAcademiaLogo />
          {UpdatePasswordForm()}
        </div>
        <div className="flex bg-playmove-blue h-[20%] rounded-bl-3xl rounded-br-[4rem] mb-6 md:mb-20">
          <PlaymoveFooterLogo />
        </div>
      </div>
    </main>
  );
}
