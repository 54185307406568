import PageWithSidebar from "../PageWithSidebar";
import { useState, useEffect } from "react";
import CursoConcluidoImg from "../../assets/img/curso-concluido.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useCoursesApi } from "../../Hooks";
import RateCourse from "./RateCourse";
import DownloadCertificateButton from "../Shared/Buttons/DownloadCertificateButton";

export default function CursoConcluido() {
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const coursesApi = useCoursesApi();
  
  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 300));
      const courseData = await coursesApi.fullCourse(id);
      if (!courseData.courseProgress?.completed) navigate(`/cursos/${id}`);
      else {
        setCourse(courseData);
        setLoading(false);
      }
    };

    loadData();
  }, []);

  return (
    <PageWithSidebar loading={loading} additionalClasses="flex flex-col">
      <div className="flex flex-row h-fit pb-5 border-b-gray-300 border-b-[3px] mb-5">
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="text-2xl cursor-pointer self-center border border-black rounded-full py-3 px-4"
          onClick={() => navigate(`/cursos/${id}`)}
        />
        <div className="ml-5 flex flex-col">
          <p className="mt-auto text-xl font-bold my-auto">Voltar ao curso</p>
        </div>
      </div>
      <div className="mx-auto flex flex-col text-center gap-5">
        <img src={CursoConcluidoImg} className="mx-auto" alt="Curso Concluido" />
        <div className="flex flex-col md:flex-row gap-y-12">
          <div className="flex flex-col text-center md:text-left">
            <h1 className="text-2xl font-dosisbold mt-6">
              Parabéns!
              <br />
              Você concluiu nosso curso.
            </h1>
          </div>
          {course?.rated && <DownloadCertificateButton
            courseId={course?.id}
            courseTitle={course?.title}
            className="mx-auto md:mx-0 md:ml-auto mt-auto self-center w-[18rem]"
            rated={course?.rated}
          />}
        </div>
        <RateCourse course={course} onSuccess={() => {
          const tempCourse = {...course};
          tempCourse.rated = true;
          setCourse(tempCourse);
          }} />
      </div>
    </PageWithSidebar>
  );
}
