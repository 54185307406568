import {ReactComponent as SearchIcon} from "../../../assets/img/icons/search.svg";

export default function SearchBar({value, onChangeText, placeholder, onClick, className}) {

    const handleKeypress = e => {
        if (e.keyCode === 13) {
            onClick();
        }
    };

    return (<div className={`${className}    flex flex-row w-52 md:w-80 min-w-fit border-playmove-blue border-r-0 border rounded-md`}>
            <input onKeyDown={handleKeypress} placeholder={placeholder} value={value} onChange={onChangeText}
                   className="px-1 py-1.5 border-0 rounded-l-md border-r-0 w-52 md:w-80 focus:ring-transparent focus:outline-0" type="text"/>
            <button aria-label="Busca"
                    className={`rounded-md border -my-[1px] -mr-[1px] border-playmove-blue bg-playmove-yellow font-bold w-fit py-[3px] px-3 hover:bg-playmove-green hover:border-playmove-green hover:text-white`}
                    onClick={onClick}>
                <SearchIcon className="h-5 w-5 stroke-playmove-blue"/>              
            </button>
        </div>
    )

}