import React from "react";
import {Link, useLocation} from "react-router-dom";

export default function SidebarButton({children, path, fill, divClassName, onClick}) {
    const location = useLocation();
    const isActive = location.pathname === path;
    var className = "flex flex-col items-center ";

    if (!isActive)
        className += fill
            ? "fill-white transition duration-200 ease-out hover:ease-in h-15 w-15  hover:fill-playmove-yellow"
            : "stroke-white transition duration-200 ease-out hover:ease-in h-15 w-15  hover:stroke-playmove-yellow";
    else className += fill ? "fill-playmove-yellow" : "stroke-playmove-yellow";

    return (
        <div className={divClassName}>
            {path ?
                (<Link to={path} className={className} onClick={onClick}>
                {children}
            </Link>) :
                (<div className={className} onClick={onClick}>
                    {children}
                </div>)}
        </div>
    );
}
