import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect } from "react";
import ConfirmButton from "./../../Shared/Buttons/ConfirmButton/index";
import CancelButton from "../../Shared/Buttons/CancelButton";
import InlineAlert from "../../Shared/InlineAlert";
import { ReactComponent as SpinnerIcon } from "../../../assets/img/icons/spinner.svg";
import { useUserApi } from "../../../Hooks";
import { emailRegex } from "../../../Configs";
import TextInput from "../../Shared/TextInput";

export default function ForgotPasswordModal({ isOpen, onClose }) {
  const [alert, setAlert] = useState({});
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const userApi = useUserApi();

  useEffect(() => {
    setEmail("");
    setAlert({});
  }, [isOpen]);

  const handleForgotPassword = async () => {
    setLoading(true);
    await new Promise((r) => setTimeout(r, 500));

    if (!emailRegex.test(email)) {
      setAlert({ type: "error", message: "Email inválido!" });
    } else {
      const forgotPasswordResult = await userApi.forgotPassword(email);
      if (forgotPasswordResult.status === 200) {
        setAlert({
          type: "success",
          message: "Solicitação enviada com sucesso",
        });
        setLoading(false);
        await new Promise((r) => setTimeout(r, 1000));
        onClose();
      }
      else 
      {
        setAlert({ type: "error", message: "Endereço de e-mail inválido ou inexistente!" });
      } 
    }
    setLoading(false);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div
            className={`flex min-h-full items-center justify-center p-4 text-center ${
              loading && "cursor-wait"
            }`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full flex flex-col max-w-md h-86 transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg leading-6 font-dosisbold">
                  Esqueci minha senha
                </Dialog.Title>
                <div className="flex flex-col px-6 py-6">
                  <div
                    className={`text-center text-md rounded-lg mb-2 font-bold py-1 transition-opacity duration-200 ease-in-out`}
                  >
                    Para redefinir sua senha, insira o e-mail associado à sua
                    conta
                  </div>

                  <InlineAlert
                    alert={alert}
                    visible={alert.type === "error" || alert.type === "success"}
                  />
                  {loading ? (
                    <SpinnerIcon className="m-auto w-16 h-16" />
                  ) : (
                    <TextInput
                      id="forgotPasswordEmail"
                      label="E-mail"
                      placeholder="seu e-mail"
                      type="text"
                      error={alert.type === "error"}
                      value={email}
                      onChangeText={(e) => setEmail(e.target.value)}
                      className="mt-2"
                      size="sm"
                    />
                  )}

                  <div className="mt-5 ml-auto flex flex-row gap-x-3">
                    <CancelButton onClick={onClose} />

                    <ConfirmButton onClick={handleForgotPassword} />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
