import { useState, useEffect, useMemo } from "react";
import { ReactComponent as SpinnerIcon } from "../../../assets/img/icons/spinner.svg";
import { useMetricApi, useStateApi } from "../../../Hooks";
import { Table } from "../../Shared/Table";
import DateRangeSelector from "../../Shared/DateRangeSelector";
import { Pagination } from "./../../Shared/Pagination";
import CreateButton from './../../Shared/Buttons/CreateButton';
import { truncate } from "../../../Utils/TextUtils";
import { format, subDays, startOfDay, endOfDay } from 'date-fns';


export default function UsuariosCriados() {
  const [loading, setLoading] = useState(true);
  const [trailInfo, setTrailInfo] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [listData, setListData] = useState({
    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
    totalItems: 0,
  });

  const metricApi = useMetricApi();  

  const today = new Date();
  const oneWeekAgo = subDays(today, 7);
  const [dateFrom, setDateFrom] = useState(startOfDay(oneWeekAgo));
  const [dateTo, setDateTo] = useState(endOfDay(today));

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 500));
      const getTrailInfo = await metricApi.userInfoList(
        listData.page,
        listData.itemsPerPage,
        dateFrom,
        dateTo
      );
      
      setTrailInfo(Array.isArray(getTrailInfo.items) ? getTrailInfo.items : []);
      setListData({
        ...listData,
        pageCount: getTrailInfo.pageCount,
        totalItems: getTrailInfo.totalItems,
      });
      setLoading(false);
    };
    
    loadData();
  }, []);

  const handleDateFromChange = (date) => {
    setDateFrom(date);
    if (date && dateTo && date > dateTo) {
      setDateTo(null);
    }
  };
  
  const handleDateToChange = (date) => {
    setDateTo(date);
  };
  
  const reloadData = async (page, dateFromFilter = "", dateToFilter = "") => {
    setLoadingData(true);
    await new Promise((r) => setTimeout(r, 500));
  
    if (!dateFrom || !dateTo) {
      alert('Por favor, selecione ambas as datas.');
      setLoadingData(false);
      return;
    }

    const formattedDateFrom = format(dateFrom, 'yyyy-MM-dd').toString();
    const formattedDateTo = format(dateTo, 'yyyy-MM-dd').toString();

    console.log(formattedDateFrom)
  
    const getTrailInfo = await metricApi.userInfoList(
      page,
      listData.itemsPerPage,
      formattedDateFrom,
      formattedDateTo
    );
  
    setTrailInfo(Array.isArray(getTrailInfo.items) ? getTrailInfo.items : []);
    setListData({
      ...listData,
      page: page,
      pageCount: getTrailInfo.pageCount,
      totalItems: getTrailInfo.totalItems,
    });
    setLoadingData(false);
  };
  

  const handlePageClick = (event) => {
    reloadData(event.selected + 1, dateFrom, dateTo);
  };

  const handleConfirmClick = () => {
    if (dateFrom && dateTo) {
      reloadData(1, dateFrom, dateTo);
    } else {
      alert('Por favor, selecione ambas as datas.');
    }
  };
  

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        show: false
      },
      {
        Header: "Usuário",
        accessor: "usuario",
        Cell: ({ value }) =><p data-tip={value} className="font-dosisbold text-playmove-darkgray">{truncate(value, 30)}</p>,
        width: "30%"        
      },
      {
        Header: "Estado",
        accessor: "estado",
        width: "20%"
      },
      {
        Header: "Data Criação",
        accessor: "dataCriacao",
        width: "15%"
      },
      {
        Header: "Município",
        accessor: "município",
        width:"15%"
      },
    ],

    []
  );

  return (
    <div className="flex flex-col">
      {loading ? (
        <SpinnerIcon className="m-auto" />
      ) : (
        <div className="-ml-12 w-screen md:w-full md:ml-0 flex flex-col">
          <div className="flex flex-col flex-wrap items-center md:flex-row my-3 gap-y-3 gap-x-3">
          <DateRangeSelector
            dateFrom={dateFrom}
            dateTo={dateTo}
            onDateFromChange={handleDateFromChange}
            onDateToChange={handleDateToChange}
          />
        <CreateButton onClick={handleConfirmClick} className="lg:ml-auto" text="buscar"/>
          </div>           
          <Table className="w-full" columns={columns} data={trailInfo} loadingContent={loadingData} />          
          <Pagination
            className="ml-auto mt-3"
            pageCount={listData.pageCount}
            currentPage={listData.page - 1}
            handlePageClick={handlePageClick}
          />
        </div>
      )}
    </div>
  );
}
