import tw, { styled } from 'twin.macro'
import Select, { components } from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCaretDown} from '@fortawesome/free-solid-svg-icons'

const StyledReactSelect = styled(Select)`
 .Select__control{
  ${tw`border border-playmove-blue rounded-md hover:border-playmove-blue shadow-none`}
 }

 .Select__input{
  ${tw`border-none ring-0`}
  }  

  .Select__indicator-separator{
  ${tw`hidden`}
  }

  .Select__indicator{
  ${tw`!text-black`}
  }  

  .Select__clear-indicator{
  ${tw`cursor-pointer`}
  }
  
  .Select__menu-list{
    ${tw`max-h-36`}
    }

    .Select__menu{
      ${tw`rounded-md mt-1`}
      }
`;

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props} className="cursor-pointer">
      <FontAwesomeIcon className="scale-x-50 " icon={faCaretDown} />
    </components.DropdownIndicator>
  );
};


export default function Dropdown({placeholder, items, onChange, value, emptyMessage}){

    return (
        <StyledReactSelect
            options={items}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            components={{ DropdownIndicator}} 
            isClearable
            isSearchable
            className="w-56"           
            classNamePrefix={'Select'}
            noOptionsMessage={() =>{if(emptyMessage && items.length === 0){return emptyMessage}else{return "Nenhum item encontrado"}}}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: '#DEEBF7',
                primary: '#0080FB',
              },
            })}
          />
      )
}