import { useAxiosClient } from '..';

export const useClassApi = () => {
    const { axiosClient } = useAxiosClient();

    return {
        classList: async (page, itemsPerPage, nameFilter, cityFilter, stateFilter, schoolFilter) => (await axiosClient.get(`/v1/class?page=${page}&itemsPerPage=${itemsPerPage}&nameFilter=${nameFilter}&cityFilter=${cityFilter}&stateFilter=${stateFilter}&schoolFilter=${schoolFilter}`)).data,
        deleteClass: async (id) => (await axiosClient.delete(`/v1/class/${id}`)),    
        createClass: async (_class) => (await axiosClient.post(`/v1/class`, _class)),
        updateClass: async (_class) => (await axiosClient.put(`/v1/class/${_class.id}`, _class)),
        class: async (id) => (await axiosClient.get(`/v1/class/${id}`)).data,
    }
}