import { useState, useEffect, useMemo } from "react";
import { ReactComponent as SpinnerIcon } from "../../../assets/img/icons/spinner.svg";
import { useSchoolApi, useMetricApi } from "../../../Hooks";
import { Table } from "../../Shared/Table";
import { Pagination } from "../../Shared/Pagination";
import Dropdown from '../../Shared/Dropdown';

export default function ProgressosCursos() {
  const [loading, setLoading] = useState(true);
  const [courseProgresses, setCourseProgresses] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [listData, setListData] = useState({
    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
    totalItems: 0,
  });

  const schoolApi = useSchoolApi();
  const metricApi = useMetricApi();

  const [schools, setSchools] = useState([]);
  const [schoolFilter, setSchoolFilter] = useState(null);

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 500));
      const getCourseProgresses = await metricApi.courseProgressesBySchoolList(
        listData.page,
        listData.itemsPerPage,
        "",
      );
      setCourseProgresses(getCourseProgresses.items);
      setListData({
        ...listData,
        pageCount: getCourseProgresses.pageCount,
        totalItems: getCourseProgresses.totalItems,
      });
      setLoading(false);
      await loadSchools();
    };
    loadData();
  }, []);

  const loadSchools = async () => {
    const getSchools = await schoolApi.schoolList(0, 0, "", "", "");
    const schools = getSchools.items.map((school) => ({
      value: school.id,
      label: school.name,
    }));
    setSchools(schools);
  }

  const reloadData = async (page, schoolFilter = null) => {
    try {
      setLoadingData(true);

      const schoolFilterValue = schoolFilter ? schoolFilter.value : "";

      const getCourseProgresses = await metricApi.courseProgressesBySchoolList(
        page,
        listData.itemsPerPage,
        schoolFilterValue
      );

      setCourseProgresses(getCourseProgresses.items);
      setListData({
        ...listData,
        page: page,
        pageCount: getCourseProgresses.pageCount,
        totalItems: getCourseProgresses.totalItems,
      });
    } catch (error) {
      console.error("Error during data reload:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleSchoolFilterChange = async (e) => {
    setSchoolFilter(e);
    await loadSchools(e);
    reloadData(1, e);
  }


  const handlePageClick = (event) => {
    reloadData(event.selected + 1, schoolFilter);
  };

  const handleGenerateReportCourseProgress = async () => {
    const schoolFilterValue = schoolFilter ? schoolFilter.value : "";

    const getCourseProgresses = await metricApi.generateReportProgressesSchool(schoolFilterValue);

    const date = new Date();
    const formattedDate = `${date.getDate()
      .toString()
      .padStart(2, "0")}_${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}_${date.getFullYear()}`;
    const fileName = `Relatorio_Progresso_${formattedDate}.xlsx`;

    const url = window.URL.createObjectURL(new Blob([getCourseProgresses]));
    const link = document.createElement('a');
    link.href = url;

    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const columns = useMemo(() => {
    if (courseProgresses.length === 0) return [];
    const exampleRow = courseProgresses[0];

    const courses = Object.keys(exampleRow);

    return courses.map((course) => ({
      Header: course.replace(/_/g, " ").toUpperCase(),
      accessor: course,
      Cell: ({ value }) => (
        <p data-tip={value}>{value || "0%"}</p>
      ),
      width: "20%",
    }));
  }, [courseProgresses]);


  return (
    <div className="flex flex-col">
      {loading ? (
        <SpinnerIcon className="m-auto" />
      ) : (
        <div className="-ml-12 w-screen md:w-full md:ml-0 flex flex-col">
          <div className="flex flex-col flex-wrap items-center md:flex-row my-3 gap-y-3 gap-x-3">
            <Dropdown items={schools} value={schoolFilter} placeholder="Selecione uma escola" emptyMessage="Selecione uma cidade com escolas" onChange={handleSchoolFilterChange} />
            <div className="lg:ml-auto flex flex-col flex-wrap items-center md:flex-row mt-3 gap-y-3 gap-x-3">
              <button
                onClick={handleGenerateReportCourseProgress}
                disabled={schoolFilter == null || schoolFilter.value == ""}
                className={`flex flex-row items-center border-b-2 font-inter rounded w-36 py-2 ${
                  schoolFilter == null || schoolFilter.value == ""
                    ? "bg-gray-400 border-gray-400 text-gray-700 cursor-not-allowed"
                    : "bg-playmove-mediumblue border-playmove-blue text-white hover:bg-playmove-blue"
                }`}
              >
                <span className="m-auto flex flex-row items-center gap-x-1"><span className="text-sm font-bold text-white">Exportar para Excel</span></span>
              </button>
            </div>
          </div>
          <Table className="w-full" columns={columns} data={courseProgresses} loadingContent={loadingData} />
          <Pagination
            className="ml-auto mt-3"
            pageCount={listData.pageCount}
            currentPage={listData.page - 1}
            handlePageClick={handlePageClick}
          />
        </div>
      )}
    </div>
  );
}
