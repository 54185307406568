import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import TextInput from "../../Shared/TextInput";
import SaveButton from "../../Shared/Buttons/SaveButton";
import CancelButton from "../../Shared/Buttons/CancelButton";
import InlineAlert from "../../Shared/InlineAlert";
import {passwordRegex} from "../../../Configs";
import {useUserApi} from "../../../Hooks";

export default function ChangePasswordModal({ isOpen, onClose, onSuccess, email }) {
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({});
    const [passwordForm, setPasswordForm] = useState({currentPassword: '', newPassword: '', confirmPassword: ''});
    const userApi = useUserApi();

    const clearAlert = () => {
        setAlert({type:"", message: ""});
    }

    const handleSubmitNewPassword = async () => {
        setAlert({});
        var tempError = false;

        if (!passwordRegex.test(passwordForm.newPassword)) {
            setAlert({type: 'error', message: 'A senha deve ter no mínimo 6 caracteres e conter letras e números'});
            tempError = true;
        }

        else if (passwordForm.newPassword !== passwordForm.confirmPassword) {
            setAlert({type: 'error', message: 'As senhas não conferem'});
            tempError = true;
        }

        else if (passwordForm.currentPassword === passwordForm.newPassword) {
            setAlert({type: 'error', message: 'A nova senha deve ser diferente da atual'});
            tempError = true;
        }

        if (!tempError) {
            setLoading(true);
            await new Promise((r) => setTimeout(r, 500));
            const updatePasswordResult = await userApi.updateUserPassword(email, passwordForm.currentPassword, passwordForm.newPassword);
            if (updatePasswordResult.status === 200) {
                setPasswordForm({currentPassword: '', newPassword: '', confirmPassword: ''});
                onSuccess();
                onClose();
            } else
                setAlert({type: 'error', message: 'Senha inválida!'});
        }
        setLoading(false);
    }

    useEffect (() => {
        if(!isOpen)
            return;
            
        setPasswordForm({currentPassword: '', newPassword: '', confirmPassword: ''});
        setAlert({});
    }, [isOpen]);

    return (
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={onClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className={`flex min-h-full items-center justify-center p-4 text-center ${loading && "cursor-wait opacity-25"}`}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full flex flex-col max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg leading-6 font-dosisbold"
                                    >
                                        Alterar senha
                                    </Dialog.Title>

                                    <TextInput id="senhaAtual" label="Senha atual" placeholder="sua senha atual" type="password" className="mt-5"
                                               value={passwordForm.currentPassword}
                                               onChangeText={(e) => setPasswordForm({...passwordForm, currentPassword: e.target.value})}
                                               onClick={clearAlert}
                                               error={alert.type === "error"} size="sm"/>

                                    <TextInput id="senhaNova" label="Nova senha" placeholder="sua nova senha" type="password" className="mt-3"
                                               value={passwordForm.newPassword}
                                               onChangeText={(e) => setPasswordForm({...passwordForm, newPassword: e.target.value})}
                                               onClick={clearAlert}
                                               error={alert.type === "error"} size="sm"/>

                                    <TextInput id="confirmarSenhaNova" label="Confirmar nova senha" placeholder="confirme sua nova senha" type="password" className="mt-3"
                                               value={passwordForm.confirmPassword}
                                               onChangeText={(e) => setPasswordForm({...passwordForm, confirmPassword: e.target.value})}
                                               onClick={clearAlert}
                                               error={alert.type === "error"} size="sm"/>

                                    <InlineAlert alert={alert} visible={alert.type === "error" || alert.type === "success"}/>

                                    <div className="mt-5 flex flex-row ml-auto gap-x-3">                                        
                                        <CancelButton onClick={onClose}/>
                                        <SaveButton onClick={handleSubmitNewPassword}/>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
    )
}