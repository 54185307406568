import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DateRangeSelector.css';

const DateRangeSelector = ({ dateFrom, dateTo, onDateFromChange, onDateToChange }) => {
  return (
    <div className="date-range-selector">
      <div className="date-picker-container">
        <label>Data Inicial:</label>
        <DatePicker
          selected={dateFrom}
          onChange={onDateFromChange}
          dateFormat="dd/MM/yyyy"
          placeholderText="Selecione a data inicial"
          selectsStart
          startDate={dateFrom}
          endDate={dateTo}
        />
      </div>
      <div className="date-picker-container">
        <label>Data Final:</label>
        <DatePicker
          selected={dateTo}
          onChange={onDateToChange}
          dateFormat="dd/MM/yyyy"
          placeholderText="Selecione a data final"
          selectsEnd
          startDate={dateFrom}
          endDate={dateTo}
          minDate={dateFrom}
        />
      </div>
    </div>
  );
};

export default DateRangeSelector;
