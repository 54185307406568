export default function FeatureCard({title, description, icon}) {
    return (
        <div className="border-2 border-gray-300 rounded-md grid grid-rows-3 px-3 py-3">
            {icon}
            <b className="text-lg self-center">{title}</b>
            <p>
                {description}
            </p>
        </div>
    )
}