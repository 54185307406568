import { useAxiosClient } from '..';

export const useTrailsApi = () => {
    const { axiosClient, axiosClientNoResponseIntercept } = useAxiosClient(true);

    return {
        trailList: async (page, itemsPerPage, titleFilter) => (await axiosClient.get(`/v1/trail/active?page=${page}&itemsPerPage=${itemsPerPage}&withTitle=${titleFilter}`)).data,
        fullTrail: async (id) => (await axiosClient.get(`/v1/trail/${id}/full`)).data,
        trail: async (id) => (await axiosClient.get(`/v1/trail/${id}`)).data,       
        favouriteTrail: async (id) => (await axiosClientNoResponseIntercept.post(`/v1/trail/${id}/favourite`)),
        unfavouriteTrail: async (id) => (await axiosClientNoResponseIntercept.post(`/v1/trail/${id}/unfavourite`)),
        updateLessonProgressPassive: async (data) => (await axiosClientNoResponseIntercept.post(`/v1/trail/${data.trailId}/progress`, data)),
        updateLessonProgress: async (data) => (await axiosClient.post(`/v1/trail/${data.trailId}/progress`, data)),
        resetLessonProgresses: async (data) => (await axiosClient.post(`/v1/trail/${data.trailId}/progress/reset`, data)),
        getCertificateInfo: async (id) => (await axiosClient.get(`/v1/trail/${id}/certificate`)).data,
        rateTrail: async (data) => (await axiosClient.post(`/v1/trail/${data.trailId}/rating`, data)),
    }
}