import { useState, useEffect, useMemo } from "react";
import { ReactComponent as SpinnerIcon } from "../../../assets/img/icons/spinner.svg";
import { useClassApi, useStateApi, useSchoolApi } from "../../../Hooks";
import { Table } from "../../Shared/Table";
import { Pagination } from "./../../Shared/Pagination";
import SearchBar from './../../Shared/SearchBar';
import Dropdown from './../../Shared/Dropdown';
import { truncate } from "../../../Utils/TextUtils";
import DeleteItemButton from './../../Shared/Buttons/DeleteItemButton';
import EditItemButton from './../../Shared/Buttons/EditItemButton';
import CreateButton from './../../Shared/Buttons/CreateButton';
import ConfirmModal from "../../Shared/ConfirmModal";
import { useNavigate } from 'react-router-dom';
export default function Turmas() {
  const [loading, setLoading] = useState(true);
  const [classes, setClasses] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [listData, setListData] = useState({
    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
    totalItems: 0,
  });

  const classApi = useClassApi();  
  const stateApi = useStateApi(); 
  const schoolApi = useSchoolApi();
  const [search, setSearch] = useState("");
  const [states, setStates] = useState([]);
  const [stateFilter, setStateFilter] = useState(null);

  const [cities, setCities] = useState([]);
  const [cityFilter, setCityFilter] = useState(null);

  const [schools, setSchools] = useState([]);
  const [schoolFilter, setSchoolFilter] = useState(null);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalLoading, setConfirmModalLoading] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState({message: "", itemId: ""});
  const [confirmModalAlert, setConfirmModalAlert] = useState({});
  const [confirmModalButtonsEnabled, setConfirmModalButtonsEnabled] = useState(true);

  const navigate = useNavigate();


  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 500));
      const getClasses = await classApi.classList(
        listData.page,
        listData.itemsPerPage,
        "",
        "",
        ""
      );
      setClasses(getClasses.items);
      setListData({
        ...listData,
        pageCount: getClasses.pageCount,
        totalItems: getClasses.totalItems,
      });
      loadStates();
      setLoading(false);
    };

    loadData();
  }, []);

  const loadStates = async () => {
    const getStates = await stateApi.stateList();
    const states = getStates.map((state) => ({
      value: state.id,
      label: state.name,
    }));
    setStates(states);
  }

  const loadCities = async (state) => {
    if(state === null){
      setCities([]);
      setCityFilter(null);
      return;
    }

    const getCities = await stateApi.cityList(state.value);
    const cities = getCities.map((city) => ({
      value: city.id,
      label: city.name,
    }));
    setCities(cities);
  }

  const loadSchools = async (city) => {
    if(city === null){
      setSchools([]);
      setSchoolFilter(null);
      return;
    }

    const getSchools = await schoolApi.schoolList(0,0,"", city.value, stateFilter.value );
    const schools = getSchools.items.map((school) => ({
      value: school.id,
      label: school.name,
    }));
    setSchools(schools);
  }

  const reloadData = async (page, cityFilter = "", stateFilter="", schoolFilter="") => {
    setLoadingData(true);
    await new Promise((r) => setTimeout(r, 500));
    var cityFilterValue = cityFilter !== null ? cityFilter.value : "";
    var stateFilterValue = stateFilter !== null ? stateFilter.value : "";
    var schoolFilterValue = schoolFilter !== null ? schoolFilter.value : "";

    const getClasses = await classApi.classList(
      page,
      listData.itemsPerPage,
      search,
      cityFilterValue,
      stateFilterValue,
      schoolFilterValue
    );

    setClasses(getClasses.items);
    setListData({
      ...listData,
      page: page,
      pageCount: getClasses.pageCount,
      totalItems: getClasses.totalItems,
    });
    setLoadingData(false);
    
  };

  const handleSearchClick = async (search) => {    
    reloadData(1, cityFilter, stateFilter, schoolFilter);
  }
  
  const handleStateFilterChange = async (e) => {
    setStateFilter(e);
    if (e === null) {
      setCityFilter(null);
      setSchoolFilter(null); // Limpar filtro de escola ao limpar o filtro de estado
      setCities([]); // Limpar filtro de cidade ao limpar o filtro de estado
      setSchools([]); // Limpar dados de escola ao limpar o filtro de estado
      setClasses([]); // Limpar filtro de turma ao limpar o filtro de estado
    } else {
      setCityFilter(null);
      setSchoolFilter(null);
      await loadCities(e);
    }
    reloadData(1, null, e, null);
  }
  

  const handleCityFilterChange = async (e) => {
    setCityFilter(e);
    setSchoolFilter(null);
    loadSchools(e);    
    reloadData(1, e, stateFilter, null);
  }

  
  const handleSchoolFilterChange = async (e) => {
    setSchoolFilter(e);
    reloadData(1, cityFilter, stateFilter, e);
  }

  const handlePageClick = (event) => {
    reloadData(event.selected + 1,cityFilter,stateFilter);
  };

  const handleDeleteClick = async (name, id) => {
    setConfirmModalAlert({});     
    setConfirmModalButtonsEnabled(true);
    setShowConfirmModal(true);
    setConfirmModalData({message: `Tem certeza que deseja excluir a turma '${name}'?`, itemId: id, });    
  }  

  const handleEditClick = async (id) => {
    navigate(`/cadastros/turmas/${id}`);
  }  
  
  const handleCreateClick = async () => {
    navigate("/cadastros/turmas/nova");
  }  

  const handleDeleteConfirm = async () => {
    setConfirmModalLoading(true);    
    setConfirmModalButtonsEnabled(false);
    await new Promise((r) => setTimeout(r, 500));
    const deleteClass = await classApi.deleteClass(confirmModalData.itemId);
    if(deleteClass.status === 204){
      setConfirmModalLoading(false);
      setConfirmModalAlert({message: "Turma excluída com sucesso", type: "success"});
      await new Promise((r) => setTimeout(r, 1000));
      reloadData(listData.page);            
      setShowConfirmModal(false);      
    }
    else {
      setConfirmModalAlert({message: deleteClass.response.data[0], type: "error"});
      setConfirmModalLoading(false);
      setConfirmModalButtonsEnabled(true);
    }      
  }  

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        show: false
      },
      {
        Header: "Turma",
        accessor: "name",
        Cell: ({ value }) =><p data-tip={value} className="font-dosisbold text-playmove-darkgray">{truncate(value, 30)}</p>,
        width: "20%"        
      },
      {
        Header: "Escola",
        accessor: "schoolName",
        Cell: ({ value }) =><p data-tip={value}>{truncate(value, 30)}</p>,
        width: "30%"        
      },
      {
        Header: "UF",
        accessor: "stateAbbreviation",
        width: "10%"
      },
      {
        Header: "CityId",
        accessor: "cityId",
        show: false
      },
      {
        Header: "Cidade",
        accessor: "cityName",
        width:"20%"
      },
      {
        Header: "StateId",
        accessor: "stateId",
        show: false
      },  
      {
        Header: "CourseCount",
        accessor: "courseCount",
        show: false
      },          
      {
        Header: "Alunos/Cursos",
        accessor: "studentCount",
        Cell: ({ row }) =>
          <div className="flex flex-col text-base text-center">
            <p>{row.values.studentCount > 0 ? `${row.values.studentCount} aluno${row.values.studentCount > 1 ? "s" : ""}` : "-"}</p>
            <p>{row.values.courseCount > 0 ? `${row.values.courseCount} curso${row.values.courseCount > 1 ? "s" : ""}` : "-"}</p>
          </div>,
        width: "10%"
      },
      {
        Header: " ",
        accessor: "",
        Cell: ({ row }) => (
          <div className="ml-auto w-56 flex flex-row gap-x-20">
            <DeleteItemButton onClick={() => handleDeleteClick(row.values.name, row.values.id)} />
            <EditItemButton onClick={() => handleEditClick(row.values.id)}/>
          </div>
        ),
      width:"10%"
      },
    ],

    []
  );

  return (
    <div className="flex flex-col">
      {loading ? (
        <SpinnerIcon className="m-auto" />
      ) : (
        <div className="-ml-12 w-screen md:w-full md:ml-0 flex flex-col">
          <div className="flex flex-col flex-wrap items-center md:flex-row my-3 gap-y-3 gap-x-3">
          <SearchBar value={search} onClick={handleSearchClick} onChangeText={(e) => setSearch(e.target.value)} placeholder={`Buscar por nome de turma...`} />
          <Dropdown items={states} value={stateFilter} placeholder="Filtrar por estado" onChange={handleStateFilterChange}/>
          <Dropdown items={cities} value={cityFilter} placeholder="Filtrar por cidade" emptyMessage="Selecione um estado" onChange={handleCityFilterChange}/>
          <Dropdown items={schools} value={schoolFilter} placeholder="Filtrar por escola" emptyMessage="Selecione uma cidade com escolas" onChange={handleSchoolFilterChange}/>
          <CreateButton onClick={handleCreateClick} className="lg:ml-auto" text="add turma"/>
          </div>         
          <ConfirmModal isOpen={showConfirmModal} alert={confirmModalAlert} loading={confirmModalLoading} onConfirm={handleDeleteConfirm} buttonsEnabled={confirmModalButtonsEnabled} onClose={() => setShowConfirmModal(false)} message={confirmModalData.message}/>                 
          <Table className="w-full" columns={columns} data={classes} loadingContent={loadingData} />          
          <Pagination
            className="ml-auto mt-3"
            pageCount={listData.pageCount}
            currentPage={listData.page - 1}
            handlePageClick={handlePageClick}
          />
        </div>
      )}
    </div>
  );
}
