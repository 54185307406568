import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect } from "react";
import TextInput from "../../../Shared/TextInput";
import SaveButton from "../../../Shared/Buttons/SaveButton";
import CancelButton from "../../../Shared/Buttons/CancelButton";
import InlineAlert from "../../../Shared/InlineAlert";
import {
  useStudentApi,
  useStateApi,
  useSchoolApi,
  useClassApi,
} from "../../../../Hooks";
import DropdownInput from "../../../Shared/DropdownInput/index";
import { isNullOrWhitespace } from "../../../../Utils/TextUtils";
import { emailRegex } from "../../../../Configs";
import { ReactComponent as SpinnerIcon } from "../../../../assets/img/icons/spinner.svg";

export default function StudentFormModal({
  isOpen,
  onClose,
  onSuccess,
  student = null,

}) {
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({});
  const [studentForm, setStudentForm] = useState({
    id: "",
    name: "",
    email: "",
    city: null,
    state: null,
    school: null,
    class: null,
  });
  const edit = student !== null;
  const studentApi = useStudentApi();
  const schoolApi = useSchoolApi();
  const classApi = useClassApi();
  const stateApi = useStateApi();
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [schools, setSchools] = useState([]);
  const [classes, setClasses] = useState([]);
  const [buttonsEnabled, setButtonsEnabled] = useState(true);

  const loadStates = async () => {
    const getStates = await stateApi.stateList();
    const states = getStates.map((state) => ({
      value: state.id,
      label: state.abbreviation,
    }));
    setStates(states);
  };

  const loadCities = async (state) => {
    if (state === null) {
      setCities([]);
      setStudentForm({ ...studentForm, city: null });
      return;
    }

    const getCities = await stateApi.cityList(state.value);
    const cities = getCities.map((city) => ({
      value: city.id,
      label: city.name,
    }));
    setCities(cities);
  };

  const loadSchools = async (city) => {
    if (city === null) {
      setSchools([]);
      setStudentForm({ ...studentForm, school: null });
      return;
    }

    const getSchools = await schoolApi.schoolList(
      0,
      0,
      "",
      city.value,
      studentForm.state.value
    );
    const schools = getSchools.items.map((school) => ({
      value: school.id,
      label: school.name,
    }));
    setSchools(schools);
  };

  const loadClasses = async (school) => {
    if (school === null) {
      setClasses([]);
      setStudentForm({ ...studentForm, class: null });
      return;
    }

    const getClasses = await classApi.classList(
      0,
      0,
      "",
      studentForm.city.value,
      studentForm.state.value,
      school.value
    );
    const classes = getClasses.items.map((_class) => ({
      value: _class.id,
      label: _class.name,
    }));
    setClasses(classes);
  };

  const handleStateChange = async (e) => {
    clearAlert();
    setStudentForm({
      ...studentForm,
      state: e,
      city: null,
      school: null,
      class: null,
    });
    loadCities(e);
  };

  const handleCityChange = async (e) => {
    clearAlert();
    setStudentForm({ ...studentForm, city: e, school: null, class: null });
    loadSchools(e);
  };

  const handleSchoolChange = async (e) => {
    clearAlert();
    setStudentForm({ ...studentForm, school: e, class: null });
    loadClasses(e);
  };

  const handleClassChange = async (e) => {
    clearAlert();
    setStudentForm({ ...studentForm, class: e });
  };

  const clearAlert = () => {
    setAlert({ type: "", message: "" });
  };

  const handleSave = async () => {
    setButtonsEnabled(false);

    setAlert({});
    var alerts = [];

    if (isNullOrWhitespace(studentForm.name)) {
      alerts.push("O campo nome é obrigatório");
    }

    if (isNullOrWhitespace(studentForm.email)) {
      alerts.push("O campo e-mail é obrigatório");
    } else if (!emailRegex.test(studentForm.email)) {
      alerts.push("O e-mail não é válido");
    }

    if (studentForm.state === null) {
      alerts.push("O campo estado é obrigatório");
    }

    if (studentForm.city === null) {
      alerts.push("O campo cidade é obrigatório");
    }

    if (studentForm.school === null) {
      alerts.push("O campo escola é obrigatório");
    }

    if (studentForm.class === null) {
      alerts.push("O campo turma é obrigatório");
    }

    if (!alerts.length) {
      setLoading(true);
      await new Promise((r) => setTimeout(r, 500));
      const data = {
        id: studentForm.id,
        name: studentForm.name,
        email: studentForm.email,
        cityId: studentForm.city.value,
        classId: studentForm.class.value,
      };

      const request = edit
        ? await studentApi.updateStudent(data)
        : await studentApi.createStudent(data);

      await handleRequestResult(request);
    } else setAlert({ type: "error", message: alerts.join("<br/>") });

    setButtonsEnabled(true);
    setLoading(false);
  };

  const handleRequestResult = async (request) => {
    if (request.status === 200) {
      setLoading(false);
      setAlert({
        type: "success",
        message: `Aluno ${edit ? "atualizado" : "criado"} com sucesso`,
      });
      await new Promise((r) => setTimeout(r, 1000));
      onSuccess();
      onClose();
    } else setAlert({ type: "error", message: request.response});
  };

  useEffect(() => {
    if (!isOpen) return;
    setLoading(true);

    if (student !== null) {

      const state = { label: student.stateAbbreviation, value: student.stateId }
      const city = { label: student.cityName, value: student.cityId }
      const school = { label: student.schoolName, value: student.schoolId }
      const _class = { label: student.className, value: student.classId }

      setStudentForm({
        id: student.id,
        name: student.name,
        email: student.email,
        city: city,
        state: state,
        school: school,
        class: _class,
      });
      loadStates();
      loadCities(state);
      loadSchools(city);
      loadClasses(school);
    } else {
      setStudentForm({
        name: "",
        email: "",
        city: null,
        state: null,
        school: null,
        class: null,
      });
      loadStates();
      setCities([]);
      setSchools([]);
      setClasses([]);
    }    
    
    setAlert({});
    setLoading(false);
    setButtonsEnabled(true);
  }, [isOpen]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {return}} >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div
            className={`flex min-h-full items-center justify-center p-4 text-center ${
              loading && "cursor-wait"
            }`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full flex flex-col max-w-md h-[41rem] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg leading-6 font-bold">
                {edit ? "Editar" : "Criar"} Aluno
                </Dialog.Title>

                {loading ? <SpinnerIcon className="m-auto w-24 h-24"/> : <>
                <TextInput
                  id="nome"
                  label="Nome"
                  placeholder="nome completo do aluno"
                  type="text"
                  className="mt-5"
                  value={studentForm.name}
                  onChangeText={(e) =>
                    setStudentForm({ ...studentForm, name: e.target.value })
                  }
                  onClick={clearAlert}
                  error={alert.type === "error"}
                  size="sm"
                />

                <TextInput
                  id="email"
                  label="E-mail"
                  placeholder="e-mail do aluno"
                  type="text"
                  className="mt-3"
                  value={studentForm.email}
                  onChangeText={(e) =>
                    setStudentForm({ ...studentForm, email: e.target.value })
                  }
                  onClick={clearAlert}
                  error={alert.type === "error"}
                  size="sm"
                />

                <div className="mt-3 flex flex-row gap-x-3">
                  <DropdownInput
                    label="UF"
                    items={states}
                    value={studentForm.state}
                    error={alert.type === "error"}
                    inputClassName="w-16"
                    placeholder="UF"
                    onChange={handleStateChange}
                  />
                  <DropdownInput
                    label="Cidade"
                    items={cities}
                    value={studentForm.city}
                    error={alert.type === "error"}
                    className="grow"
                    placeholder="cidade"
                    emptyMessage="Selecione um estado"
                    onChange={handleCityChange}
                  />
                </div>
                <DropdownInput
                  label="Escola"
                  items={schools}
                  value={studentForm.school}
                  error={alert.type === "error"}
                  className="mt-3"
                  placeholder="escola"
                  emptyMessage="Selecione uma cidade com escolas"
                  onChange={handleSchoolChange}
                />
                <DropdownInput
                  label="Turma"
                  items={classes}
                  value={studentForm.class}
                  error={alert.type === "error"}
                  className="mt-3"
                  placeholder="turma"
                  emptyMessage="Selecione uma escola"
                  onChange={handleClassChange}
                />
                </>}

                <InlineAlert
                  alert={alert}
                  visible={alert.type === "error" || alert.type === "success"}
                />
                {(!alert.type && !edit) && (
                  <p className="text-center mt-5 font-bold">
                    A senha será enviada por e-mail ao aluno.
                  </p>
                )}

                <div className="mt-auto flex flex-row ml-auto gap-x-3">
                  <CancelButton onClick={onClose} enabled={buttonsEnabled} />
                  <SaveButton
                    onClick={handleSave}
                    enabled={buttonsEnabled}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
