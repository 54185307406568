import RatingCategory from "../../Shared/RatingCategory";
import { useState, useEffect } from "react";
import TextBox from "../../Shared/TextBox";
import { useCoursesApi } from "../../../Hooks";
import {ReactComponent as SpinnerIcon} from "../../../assets/img/icons/spinner.svg";
import SendButton from "../../Shared/Buttons/SendButton";

const ratingCategories = [
	"As vídeoaulas gravadas são de fácil acesso.",
	"As vídeoaulas possuem boa qualidade de imagem e áudio.",
	"Existe facilidade no manuseio da ferramenta EAD.",
	"Os temas abordados são claros e objetivos.",
	"O domínio do assunto pelo orientador é demonstrado nas vídeoaulas.",
	"Aplicarei o conhecimento no meu planejamento escolar.",
];

export default function RateCourse({ course, onSuccess }) {
	const [ratings, setRatings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState("");
    const courseApi = useCoursesApi();
    const [buttonEnabled, setButtonEnabled] = useState(false);


    const handleSubmitRating = async () => {
        if(!buttonEnabled)
            return;

        setLoading(true);
        const ratingData = {
            courseId: course.id,
            comment,
            categories : ratings
        };     

        await new Promise((r) => setTimeout(r, 500));
        const response = await courseApi.rateCourse(ratingData);
        if (response.status === 200) {
            onSuccess();
        }
        setLoading(false);
    }

    useEffect(() => {
        setButtonEnabled(ratings.filter((rating) => rating).length === ratingCategories.length);
    }
    , [ratings]);

	return (
		!course?.rated ? (
			<div className="flex flex-col"> {loading ? <SpinnerIcon className="mx-auto" /> : <>
				<p className="font-dosisbold text-xl text-left text-playmove-darkgray">
					Avalie este curso:
				</p>
				<p className="mt-3 text-left font-inter text-sm">
					Avalie nosso curso e, em seguida, receba seu
					certificado.
					<br />
					Selecione sua nota para cada categoria abaixo:
				</p>

				<div className="mt-8 flex flex-col gap-y-10">
					{ratingCategories.map((category, index) => {
						return (
							<RatingCategory
								key={index}
								value={ratings[index]?.rating}
								onChange={(value) => {
								const newRatings = [...ratings];
                                newRatings[index] = {
                                    name: category,
                                    rating: value
                                };
                                setRatings(newRatings);
                                }}
								category={category}
							/>
						);
					})}

					<TextBox
						label="Deixe aqui um comentário"
						className="text-left"
                        id="comment"
                        value={comment}
                        onChangeText={(e) => {
                            setComment(e.target.value);
                        }
                    }
					/>
				</div>
				<SendButton  
                onClick={handleSubmitRating} 
                className="mx-auto mt-5"
                enabled={buttonEnabled}/></>}
			</div>
		) : <span className="font-dosisbold">Obrigado pela sua avaliação!</span>
	);
}
