import {ReactComponent as CursosIcon} from "../../assets/img/icons/cursos.svg";
import {ReactComponent as AlunosIcon} from "../../assets/img/icons/alunos.svg";
import {useContext, useEffect, useState} from "react";
import {useHomeInfoApi} from "../../Hooks";
import {storageUrl} from "../../Configs";
import ReactPlayer from "react-player";
import FeatureCard from "./FeatureCard";
import PageWithSidebar from "../PageWithSidebar";
import { UserInfoContext } from "../../Contexts/UserInfoContext";
import { Link } from 'react-router-dom';

export default function Home() {
    const [loading, setLoading] = useState(true);
    const [homeInfo, setHomeInfo] = useState({ description: '' });
    const homeInfoApi = useHomeInfoApi();
    const [userInfo] = useContext(UserInfoContext);
    const userIsAdmin = userInfo.role === "Admin" || userInfo.role === "PlaymoveAdmin";
    const paragraphs = homeInfo.description.split('\n').filter((paragraph) => paragraph.trim() !== '');
    useEffect(() => {
        setLoading(true);
        const loadData = async () => {            
            await new Promise((r) => setTimeout(r, 300));
            const getHomeInfo = await homeInfoApi.homeInfo();
            if(getHomeInfo){
            setHomeInfo(getHomeInfo);
            setLoading(false);
            }
        };
        loadData();
    }, []);

    return (
        <PageWithSidebar loading={loading}>{homeInfo && (
            <div className="flex flex-col gap-y-10">
                <div className="flex sm:px-16 h-32 sm:h-56 3xl:h-96 shrink-0 bg-no-repeat bg-cover"
                     style={{backgroundImage: `url('${storageUrl}/${homeInfo.imagePath}')`}}>
                </div>

                <div className="row-span-2 grid gap-x-10 gap-y-5 md:grid-cols-3 md:gap-y-0">
                    <div className="grid col-span-2">
                        <div className="relative pt-[56.5%]">
                            <ReactPlayer className="absolute top-0 left-0" width="100%" height="100%" url={homeInfo.videoUrl} loop playing controls />
                        </div>
                    </div>
                    <div>
                        <b className="text-2xl font-dosisbold">{homeInfo.title}</b>
                        {paragraphs.map((paragraph, index) => (
                        <p className="mt-3 text text-lg font-inter" key={index}>
                        {homeInfo.description && paragraph}
                      </p>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col gap-y-10">
                    <div className="grid gap-x-6 gap-y-6 h-56 md:grid-cols-5">
                        <div className="featureCard">
                            <Link to="/cursos">
                                <FeatureCard
                                    title="Cursos"
                                    description="Acesses os cursos e se prepare para transformar a educação."
                                    icon={<CursosIcon className="stroke-black h-16" />}
                                />
                            </Link>
                        </div>
                        {userIsAdmin && (
                            <div className="featureCard">
                                <Link to="/cadastros">
                                    <FeatureCard
                                        title="Cadastros"
                                        description="Cadastre seus professores, turmas e escolas."
                                        icon={<AlunosIcon className="fill-black h-16" />}
                                    />
                                </Link>
                            </div>
                        )}
                    </div>
                </div> 
            </div>
        )}</PageWithSidebar>
    );
}