import { useState, useEffect, useMemo } from "react";
import { ReactComponent as SpinnerIcon } from "../../../assets/img/icons/spinner.svg";
import { useSchoolApi, useStateApi } from "../../../Hooks";
import { Table } from "../../Shared/Table";
import { Pagination } from "./../../Shared/Pagination";
import SearchBar from './../../Shared/SearchBar';
import Dropdown from './../../Shared/Dropdown';
import { truncate } from "../../../Utils/TextUtils";
import DeleteItemButton from './../../Shared/Buttons/DeleteItemButton';
import EditItemButton from './../../Shared/Buttons/EditItemButton';
import CreateButton from './../../Shared/Buttons/CreateButton';
import ConfirmModal from "../../Shared/ConfirmModal";
import SchoolFormModal from './SchoolFormModal/index';

export default function Escolas() {
  const [loading, setLoading] = useState(true);
  const [schools, setSchools] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [listData, setListData] = useState({
    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
    totalItems: 0,
  });

  const schoolApi = useSchoolApi();  
  const stateApi = useStateApi(); 
  const [search, setSearch] = useState("");
  const [states, setStates] = useState([]);
  const [stateFilter, setStateFilter] = useState(null);

  const [cities, setCities] = useState([]);
  const [cityFilter, setCityFilter] = useState(null);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalLoading, setConfirmModalLoading] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState({message: "", itemId: ""});
  const [confirmModalAlert, setConfirmModalAlert] = useState({});
  const [confirmModalButtonsEnabled, setConfirmModalButtonsEnabled] = useState(true);

  const [showSchoolFormModal, setShowSchoolFormModal] = useState(false);
  const [editSchoolData, setEditSchoolData] = useState(null);

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 500));
      const getSchools = await schoolApi.schoolList(
        listData.page,
        listData.itemsPerPage,
        "",
        "",
        ""
      );
      setSchools(getSchools.items);
      setListData({
        ...listData,
        pageCount: getSchools.pageCount,
        totalItems: getSchools.totalItems,
      });
      loadStates();
      setLoading(false);
    };

    loadData();
  }, []);

  const loadStates = async () => {
    const getStates = await stateApi.stateList();
    const states = getStates.map((state) => ({
      value: state.id,
      label: state.name,
    }));
    setStates(states);
  }

  const loadCities = async (state) => {
    if(state === null){
      setCities([]);
      setCityFilter(null);
      return;
    }

    const getCities = await stateApi.cityList(state.value);
    const cities = getCities.map((city) => ({
      value: city.id,
      label: city.name,
    }));
    setCities(cities);
  }

  const reloadData = async (page, cityFilter = "", stateFilter="") => {
    setLoadingData(true);
    await new Promise((r) => setTimeout(r, 500));
    var cityFilterValue = cityFilter !== null ? cityFilter.value : "";
    var stateFilterValue = stateFilter !== null ? stateFilter.value : "";

    const getSchools = await schoolApi.schoolList(
      page,
      listData.itemsPerPage,
      search,
      cityFilterValue,
      stateFilterValue
    );

    setSchools(getSchools.items);
    setListData({
      ...listData,
      page: page,
      pageCount: getSchools.pageCount,
      totalItems: getSchools.totalItems,
    });
    setLoadingData(false);
    
  };

  const handleSearchClick = async (search) => {    
    reloadData(1, cityFilter, stateFilter);
  }
  
  const handleStateFilterChange = async (e) => {
    setStateFilter(e);
    setCityFilter(null);
    loadCities(e);
    reloadData(1, null, e);
  }

  const handleCityFilterChange = async (e) => {
    setCityFilter(e);
    reloadData(1, e, stateFilter);
  }

  const handlePageClick = (event) => {
    reloadData(event.selected + 1, cityFilter, stateFilter);
  };

  const handleDeleteClick = async (name, id) => {
    setConfirmModalAlert({});     
    setConfirmModalButtonsEnabled(true);
    setShowConfirmModal(true);
    setConfirmModalData({message: `Tem certeza que deseja excluir a escola '${name}'?`, itemId: id, });    
  }  

  const handleEditClick = async (school) => {
    setEditSchoolData(school);
    setShowSchoolFormModal(true);  
  }  
  
  const handleCreateClick = async () => {
    setEditSchoolData(null);
    setShowSchoolFormModal(true);   
  }  

  const handleDeleteConfirm = async () => {
    setConfirmModalLoading(true);    
    setConfirmModalButtonsEnabled(false);
    await new Promise((r) => setTimeout(r, 500));
    const deleteSchool = await schoolApi.deleteSchool(confirmModalData.itemId);
    if(deleteSchool.status === 204){
      setConfirmModalLoading(false);
      setConfirmModalAlert({message: "Escola excluída com sucesso", type: "success"});
      await new Promise((r) => setTimeout(r, 1000));
      reloadData(listData.page);            
      setShowConfirmModal(false);      
    }
    else {
      setConfirmModalAlert({message: deleteSchool.response.data[0], type: "error"});
      setConfirmModalLoading(false);
      setConfirmModalButtonsEnabled(true);
    }      
  }  

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        show: false
      },
      {
        Header: "Escola",
        accessor: "name",
        Cell: ({ value }) =><p data-tip={value} className="font-dosisbold text-playmove-darkgray">{truncate(value, 30)}</p>,
        width: "30%"        
      },
      {
        Header: "UF",
        accessor: "stateAbbreviation",
        width: "10%"
      },
      {
        Header: "CityId",
        accessor: "cityId",
        show: false
      },
      {
        Header: "Cidade",
        accessor: "cityName",
        width:"20%"
      },
      {
        Header: "StateId",
        accessor: "stateId",
        show: false
      },
      {
        Header: "Turmas",
        accessor: "classCount",
        Cell: ({ value }) =>
          value > 0 ? `${value} turma${value > 1 ? "s" : ""}` : "-",
        width: "20%"
      },
      {
        Header: " ",
        accessor: "",
        Cell: ({ row }) => (
          <div className="ml-auto w-56 flex flex-row gap-x-20">
            <DeleteItemButton onClick={() => handleDeleteClick(row.values.name, row.values.id)} />
            <EditItemButton onClick={() => handleEditClick(row.values)}/>
          </div>
        ),
      width:"10%"
      },
    ],

    []
  );

  return (
    <div className="flex flex-col">
      {loading ? (
        <SpinnerIcon className="m-auto" />
      ) : (
        <div className="-ml-12 w-screen md:w-full md:ml-0 flex flex-col">
          <div className="flex flex-col flex-wrap items-center md:flex-row my-3 gap-y-3 gap-x-3">
          <SearchBar value={search} onClick={handleSearchClick} onChangeText={(e) => setSearch(e.target.value)} placeholder={`Buscar por nome de escola...`} />
          <Dropdown items={states} value={stateFilter} placeholder="Filtrar por estado" onChange={handleStateFilterChange}/>
          <Dropdown items={cities} value={cityFilter} placeholder="Filtrar por cidade" emptyMessage="Selecione um estado" onChange={handleCityFilterChange}/>
          <CreateButton onClick={handleCreateClick} className="lg:ml-auto" text="add escola"/>
          </div> 
          <SchoolFormModal isOpen={showSchoolFormModal} school={editSchoolData} onSuccess={() => reloadData(listData.page)} onClose={() => setShowSchoolFormModal(false)} />
          <ConfirmModal isOpen={showConfirmModal} alert={confirmModalAlert} loading={confirmModalLoading} onConfirm={handleDeleteConfirm} buttonsEnabled={confirmModalButtonsEnabled} onClose={() => setShowConfirmModal(false)} message={confirmModalData.message}/>                 
          <Table className="w-full" columns={columns} data={schools} loadingContent={loadingData} />          
          <Pagination
            className="ml-auto mt-3"
            pageCount={listData.pageCount}
            currentPage={listData.page - 1}
            handlePageClick={handlePageClick}
          />
        </div>
      )}
    </div>
  );
}
