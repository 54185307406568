import { useAxiosClient } from '../';

export const useMetricApi = () => {
    const { axiosClient } = useAxiosClient(true);

    return {
        trailInfoList: async (page, itemsPerPage, dateFromFilter, dateToFilter) => (await axiosClient.get(`/v1/metric/TrailInfo?page=${page}&itemsPerPage=${itemsPerPage}&dateFrom=${dateFromFilter}&dateTo=${dateToFilter}`)).data,
        trailInfoReport: async (dateFromFilter, dateToFilter) => (await axiosClient.get(`/v1/metric/TrailInfo?dateFrom=${dateFromFilter}&dateTo=${dateToFilter}`)).data,
        userInfoList: async (page, itemsPerPage, dateFromFilter, dateToFilter) => (await axiosClient.get(`/v1/metric/UserInfo?page=${page}&itemsPerPage=${itemsPerPage}&dateFrom=${dateFromFilter}&dateTo=${dateToFilter}`)).data,
        userCountByCityInPeriodList: async (page, itemsPerPage, dateFromFilter, dateToFilter) => (await axiosClient.get(`/v1/metric/UserCountByCityInPeriod?page=${page}&itemsPerPage=${itemsPerPage}&dateFrom=${dateFromFilter}&dateTo=${dateToFilter}`)).data,
        courseProgressesBySchoolList: async (page, itemsPerPage, schoolId) => (await axiosClient.get(`/v1/metric/CourseProgressesBySchool?page=${page}&itemsPerPage=${itemsPerPage}&schoolId=${schoolId}`)).data,
        generateReportProgressesSchool: async (schoolId) => (await axiosClient.get(`/v1/metric/GenerateReportCourseProgresses?schoolId=${schoolId}`,{
            responseType: 'blob',
        })).data,
    }
}