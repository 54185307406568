import PageWithSidebar from "../PageWithSidebar";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Fragment, useEffect, useRef, useState } from "react";
import { useCoursesApi } from "../../Hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronLeft,
	faDownload,
	faCheck,
} from "@fortawesome/free-solid-svg-icons";
import ReactPlayer from "react-player";
import { storageUrl } from "../../Configs";
import { ReactComponent as SpinnerIcon } from "../../assets/img/icons/spinner.svg";
import Collapsible from "react-collapsible";
import {
	formatMinutesIntoHoursAndMinutes,
	truncate,
} from "../../Utils/TextUtils";
import RateModuleModal from "../Shared/RateModuleModal";
import DownloadCertificateButton from "../Shared/Buttons/DownloadCertificateButton";
import ReactTooltip from "react-tooltip";

export default function Cursos() {
	const lessonDivRef = useRef(null);
	const videoRef = useRef(null);
	const bottomRef = useRef(null);
	const [loading, setLoading] = useState(true);
	const [course, setCourse] = useState(undefined);
	const [selectedLessonId, setSelectedLessonId] = useState(undefined);
	const [selectedLessonData, setSelectedLessonData] = useState(undefined);
	const [openModules, setOpenModules] = useState([]);
	const [moduleToBeRated, setModuleToBeRated] = useState("");
	const [showRateModuleModal, setShowRateModuleModal] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);
	const [progress, setProgress] = useState(0);
	const { id } = useParams();
	const navigate = useNavigate();
	const coursesApi = useCoursesApi();

	useEffect(() => {
		setLoading(true);
		const loadData = async () => {
			await new Promise((r) => setTimeout(r, 300));
			const fullCourse = await coursesApi.fullCourse(id);
			setCourse(fullCourse);

			const firstModuleWithLessProgress =
				fullCourse.modules.find((module) =>
					module.lessons.find(
						(lesson) =>
							lesson.lessonProgress === null ||
							lesson.lessonProgress.progress < 100
					)
				) ?? fullCourse.modules[0];
			const firstLessonWithLessProgress =
				firstModuleWithLessProgress?.lessons.find(
					(lesson) =>
						lesson.lessonProgress === null ||
						lesson.lessonProgress.progress < 100
				) ?? firstModuleWithLessProgress?.lessons[0];

			toggleOpenModule(firstModuleWithLessProgress?.id);
			setSelectedLessonData(firstLessonWithLessProgress);
			setSelectedLessonId(firstLessonWithLessProgress?.id);
			setLoading(false);
		};
		loadData();
	}, []);

	useEffect(() => {
		var lessonCount = 0;
		var completeLessonCount = 0;
		if (course) {
			course.modules.forEach((module) => {
				module.lessons.forEach((lesson) => {
					lessonCount++;
					if (lesson.lessonProgress?.progress === 100) {
						completeLessonCount++;
					}
				});
			});

			if (!course.courseProgress && completeLessonCount >= 1) {
				const tempCourse = course;
				tempCourse.courseProgress = {};
				setCourse(tempCourse);
			}
		}

		var tempProgress = completeLessonCount / lessonCount;
		setProgress(tempProgress);
	}, [course]);

	const redirectIfCourseIsNotActive = () => {
		return !course.active ? <Navigate to="/error404" /> : null;
	};

	const downloadFile = (url) => {
		window.open(url, "_blank");
	};

	const selectLesson = (lesson) => {
		if (lesson.id === selectedLessonId) {
			return;
		}
		setSelectedLessonData(undefined);
		setSelectedLessonId(lesson.id);
		setTimeout(() => {
			setSelectedLessonData(lesson);
		}, 500);
	};

	const scrollToVideo = () => {
		lessonDivRef.current.scrollIntoView({ behavior: "smooth" });
	};

	const toggleOpenModule = (moduleId) => {
		if (openModules.includes(moduleId)) {
			setOpenModules(openModules.filter((id) => id !== moduleId));
		} else {
			setOpenModules([...openModules, moduleId]);
		}
	};

	const completeLesson = async (lessonId) => {
		const module = course.modules.find((module) =>
			module.lessons.find((lesson) => lesson.id === lessonId)
		);
		const lesson = module.lessons.find(
			(lesson) => lesson.id === lessonId
		);
		lesson.lessonProgress = { progress: 100 };
		const updateProgressRequest = await coursesApi.updateLessonProgress(
			{
				courseId: course.id,
				lessonId: lessonId,
				progress: 100,
			}
		);
		if (updateProgressRequest.status === 200) {
			setCourse({ ...course });
			goToNextLesson(module, lessonId);
		} else {
			window.location.reload();
		}
	};

	const onCloseRateModuleModal = async () => {
		const everyLessonCompleted = course.modules.every((module) =>
			module.lessons.every(
				(lesson) => lesson.lessonProgress?.progress === 100
			)
		);

		if (everyLessonCompleted) {
			navigate(`/cursos/${course.id}/concluido`);
		}

		setShowRateModuleModal(false);
	};

	const updateLessonProgress = async (lessonId, videoProgress) => {
		const module = course.modules.find((module) =>
			module.lessons.find((lesson) => lesson.id === lessonId)
		);
		const lesson = module.lessons.find(
			(lesson) => lesson.id === lessonId
		);
		if (lesson.lessonProgress?.progress === 100) return;

		const currentVideoDuration = videoRef.current.getDuration();
		const currentVideoTimestamp = Math.ceil(
			videoProgress.playedSeconds
		);
		const currentProgress = Math.ceil(
			(videoProgress.playedSeconds / currentVideoDuration) * 100
		);

		if (currentProgress < lesson.lessonProgress?.progress) return;

		lesson.lessonProgress = {
			progress: currentProgress,
			timestamp: currentVideoTimestamp,
		};

		coursesApi.updateLessonProgressPassive({
			lessonId: lessonId,
			courseId: course.id,
			progress: currentProgress,
			timeStamp: currentVideoTimestamp,
		});
	};

	const resetLessonProgresses = async () => {
		setLoading(true);
		await coursesApi.resetLessonProgresses({ courseId: course.id });
		window.location.reload();
	};

	const resumeLesson = async (lessonId) => {
		if (firstLoad) {
			setFirstLoad(false);
		} else {
			scrollToVideo();
		}

		const module = course.modules.find((module) =>
			module.lessons.find((lesson) => lesson.id === lessonId)
		);
		const lesson = module.lessons.find(
			(lesson) => lesson.id === lessonId
		);
		if (
			!lesson.lessonProgress?.timestamp ||
			!lesson.lessonProgress?.progress ||
			lesson.lessonProgress?.progress === 100
		)
			return;

		videoRef.current.seekTo(
			parseInt(lesson.lessonProgress.timestamp),
			"seconds"
		);
		scrollToVideo();
	};

	const goToNextLesson = (currentModule, currentLessonId) => {
		const everyLessonCompleted = course.modules.every((module) =>
			module.lessons.every(
				(lesson) => lesson.lessonProgress?.progress === 100
			)
		);

		if (everyLessonCompleted) {
			if (!currentModule.rated) {
				setModuleToBeRated(currentModule.id);
				setShowRateModuleModal(true);
			} else {
				navigate(`/cursos/${course.id}/concluido`);
			}
			return;
		}

		const currentLessonIndex = currentModule.lessons.findIndex(
			(lesson) => lesson.id === currentLessonId
		);
		const currentModuleIndex = course.modules.indexOf(currentModule);
		const stayInSameModule =
			currentLessonIndex !== currentModule.lessons.length - 1;
		const nextModule = stayInSameModule
			? currentModule
			: course.modules[currentModuleIndex + 1];
		const nextLesson = stayInSameModule
			? nextModule?.lessons[currentLessonIndex + 1]
			: nextModule?.lessons[0];
		if (nextLesson) {
			setSelectedLessonData(undefined);

			const moduleIsOpened = openModules.includes(nextModule?.id);
			if (!moduleIsOpened) toggleOpenModule(nextModule.id);
			if (!stayInSameModule && !currentModule.rated) {
				setModuleToBeRated(currentModule.id);
				setShowRateModuleModal(true);
			}

			setSelectedLessonId(nextLesson.id);
			setTimeout(() => {
				setSelectedLessonData(nextLesson);
			}, 500);
		} else {
			setSelectedLessonData(undefined);
			const firstModuleWithLessProgress = course.modules.find(
				(module) =>
					module.lessons.find(
						(lesson) =>
							lesson.lessonProgress === null ||
							lesson.lessonProgress.progress < 100
					)
			);
			const firstLessonWithLessProgress =
				firstModuleWithLessProgress?.lessons.find(
					(lesson) =>
						lesson.lessonProgress === null ||
						lesson.lessonProgress.progress < 100
				);

			const moduleIsOpened = openModules.includes(
				firstModuleWithLessProgress?.id
			);
			if (!moduleIsOpened)
				toggleOpenModule(firstModuleWithLessProgress?.id);

			if (
				!firstModuleWithLessProgress.id !== currentModule.id &&
				!currentModule.rated
			) {
				setModuleToBeRated(currentModule.id);
				setShowRateModuleModal(true);
			}

			setSelectedLessonId(firstLessonWithLessProgress?.id);
			setTimeout(() => {
				setSelectedLessonData(firstLessonWithLessProgress);
			}, 500);
		}
	};

	const Lesson = () => {
		return (
			<div ref={lessonDivRef} className="flex flex-1 flex-col">
				<div className="relative pt-[56.5%]">
					<ReactPlayer
						className="absolute top-0 left-0"
						ref={videoRef}
						width="100%"
						height="100%"
						url={selectedLessonData.videoUrl}
						controls
						onEnded={async () =>
							await completeLesson(
								selectedLessonData.id
							)
						}
						onReady={() =>
							resumeLesson(selectedLessonData.id)
						}
						onProgress={(videoProgress) =>
							updateLessonProgress(
								selectedLessonData.id,
								videoProgress
							)
						}
						progressInterval={10000}
					/>
				</div>
				<div className="flex flex-col lg:flex-row flex-wrap gap-x-10 gap-y-5 mt-8">
					<div className="flex flex-col flex-1 grow">
						<p className="text-2xl font-dosisbold">
							{selectedLessonData.title}
						</p>

						<p className="text-lgtext-justify mt-3 font-inter text-playmove-darkgray"
						style={{ whiteSpace: 'pre-line' }}> 
						{selectedLessonData.description}
						</p>	
					</div>
					{selectedLessonData.extraMaterialPath && (
						<div className="flex flex-col">
							<p className="text-2xl font-dosisbold">
								Materiais
							</p>
							<button
								className="bg-playmove-yellow hover:bg-playmove-pink hover:text-white rounded-lg py-3 w-48 font-bold flex flex-row gap-x-5 mt-3"
								onClick={() =>
									downloadFile(
										`${storageUrl}/${selectedLessonData.extraMaterialPath}`
									)
								}>
								<FontAwesomeIcon
									className="ml-auto h-10 w-10"
									icon={faDownload}
								/>{" "}
								<p className="self-center mr-auto font-dosisbold">
									Fazer download
								</p>
							</button>
						</div>
					)}
				</div>
			</div>
		);
	};

	const scrollToBottom = () => {
		if (!window.matchMedia("(min-width: 768px)").matches) {
			setTimeout(
				() =>
					bottomRef.current.scrollIntoView({
						behavior: "smooth",
					}),
				500
			);
		}
	};

	const handleCourseProgressClick = () => {
		if (progress >= 1) {
			resetLessonProgresses();
			return;
		}

		scrollToVideo();
	};

	const ModuleList = () => {
		return (
			<div className="flex flex-col w-[100vw] -mx-12 -mb-8 mt-6 md:mx-auto md:mb-0 md:mt-0 md:w-[22rem]">
				{course.modules.map((module, moduleIndex) => {
					const moduleIsSelected = openModules.includes(
						module.id
					);
					const rounded =
						moduleIndex === 0
							? "md:rounded-t-lg"
							: moduleIndex ===
									course.modules.length -
										1 &&
							  !moduleIsSelected
							? "md:rounded-b-lg"
							: "";
					const moduleText = `${
						moduleIndex + 1
					}   ${truncate(module.title, 40)}`;
					const checked = module.lessons.every(
						(lesson) =>
							lesson.lessonProgress?.progress ===
							100
					) && (
						<FontAwesomeIcon
							className="ml-auto h-5 w-5"
							color="#FFFFFF"
							icon={faCheck}
						/>
					);
					const moduleClass = `font-dosisbold flex cursor-pointer bg-playmove-blue text-playmove-yellow min-w-full py-4 px-5 text-base  whitespace-pre  ${rounded}`;

					return (
						<Fragment key={module.id}>
							<Collapsible
								trigger={
									<>
										{moduleText}{" "}
										{checked}
									</>
								}
								open={moduleIsSelected}
								triggerClassName={moduleClass}
								triggerOpenedClassName={
									moduleClass
								}
								onTriggerOpening={() => {
									toggleOpenModule(
										module.id
									);
									scrollToBottom();
								}}
								onTriggerClosing={() =>
									toggleOpenModule(
										module.id
									)
								}>
								{module.lessons.map(
									(lesson, index) => {
										const textLesson = `${truncate(
											lesson.title,
											37
										)}`;
										const roundedLesson =
											index ===
												module
													.lessons
													.length -
													1 &&
											moduleIndex ===
												course
													.modules
													.length -
													1
												? "md:rounded-b-lg"
												: "";
										const background =
											lesson.id ===
											selectedLessonId
												? "bg-playmove-yellow"
												: lesson
														.lessonProgress
														?.progress ===
												  100
												? "bg-playmove-mediumblue"
												: "bg-playmove-lightblue";
										const cursorLesson =
											!(
												lesson.id ===
												selectedLessonId
											)
												? "cursor-pointer"
												: "";
										const checked =
											lesson
												.lessonProgress
												?.progress ===
												100 && (
												<FontAwesomeIcon
													className="ml-auto h-5 w-5"
													color={
														lesson.id ===
														selectedLessonId
															? "#333333"
															: "#60EFB1"
													}
													icon={
														faCheck
													}
												/>
											);

										return (
											<div
												ref={
													bottomRef
												}
												key={
													lesson.id
												}
												onClick={() =>
													selectLesson(
														lesson
													)
												}
												className={`font-bold flex flex-row ${cursorLesson} ${background} ${roundedLesson} text-playmove-darkgray py-4 px-5 text-md transition-all ease-in-out`}>
												{
													textLesson
												}{" "}
												{
													checked
												}
											</div>
										);
									}
								)}
							</Collapsible>
						</Fragment>
					);
				})}
			</div>
		);
	};

	const CourseInfo = () => {
		return (
			<div className="flex flex-1 grow flex-col">
				<p className="text-2xl font-dosisbold">Sobre o curso</p>
            <p
                className="text-xl mt-3 font-inter text-playmove-darkgray">
            </p>
				<div className="flex flex-row mt-5">
					<div className="flex flex-col">
						<p className="text-2xl font-dosisbold">
							Módulos:
						</p>
						<p className="text-xl text-justify mt-1 font-bold">
							{course.modules.length}
						</p>
					</div>
					<div className="flex flex-col ml-16">
						<p className="text-2xl font-dosisbold">
							Duração:
						</p>
						<p className="text-xl text-justify mt-1 font-bold">
							{formatMinutesIntoHoursAndMinutes(
								course.duration
							)}{" "}
							hora (s)
						</p>
					</div>
				</div>
			</div>
		);
	};

	const CourseProgress = () => {
		return (
			<div className="flex flex-col md:w-[22rem] md:mx-auto">
				<button
					onClick={handleCourseProgressClick}
					className="font-dosisbold self-center bg-playmove-green rounded-xl py-3 w-full  text-xl font-bold text-playmove-blue border-playmove-darkgreen border-b-[3px] hover:bg-playmove-yellow">
					{course.courseProgress
						? progress >= 1
							? "Rever curso"
							: course.courseProgress.completed
							? "Continuar revendo"
							: "Continuar"
						: "Iniciar"}
				</button>
				<div className="mt-3 mb-3 w-full bg-playmove-lightblue rounded-full h-2.5">
					<div
						className="bg-playmove-green h-2.5 rounded-full"
						data-tip={`${Math.round(
							progress * 100
						)}% do curso concluído`}
						data-place="bottom"
						style={{
							width: `${progress * 100}%`,
						}}></div>
				</div>
				{course.courseProgress?.completed && (
					<DownloadCertificateButton
						courseId={course.id}
						courseTitle={course.title}
						className="mt-auto self-center w-full"
						rated={course.rated}
					/>
				)}
			</div>
		);
	};

	return (
		<PageWithSidebar
			loading={loading}
			additionalClasses="overflow-x-hidden">
			{course && (
				<>
					{redirectIfCourseIsNotActive()}
					<ReactTooltip className="!rounded-xl" />
					<RateModuleModal
						moduleId={moduleToBeRated}
						isOpen={showRateModuleModal}
						onClose={onCloseRateModuleModal}
					/>
					<div className="flex flex-col">
						<div className="flex flex-row h-fit pb-5 border-b-gray-300 border-b-[3px]">
							<FontAwesomeIcon
								icon={faChevronLeft}
								className="text-2xl cursor-pointer self-center border border-black rounded-full py-3 px-4"
								onClick={() =>
									navigate("/cursos")
								}
							/>
							<div className="ml-5 flex flex-col text-playmove-darkgray">
								<p className="mt-auto text-xl font-bold">
									Curso
								</p>
								<p className="mb-auto text-2xl font-bold font-dosisbold">
									{course.title}
								</p>
							</div>
						</div>
					</div>
					<div className="flex flex-col-reverse lg:flex-row  flex-wrap-reverse mt-10 gap-x-16 gap-y-3 border-b-gray-300 border-b-[3px] pb-10">
						{CourseInfo()}
						{CourseProgress()}
					</div>
					{course.modules.length > 0 && (
						<div className="flex flex-col lg:flex-row mt-10 gap-x-16 gap-y-3">
							{selectedLessonData ? (
								Lesson()
							) : (
								<SpinnerIcon className="mt-32 mx-auto" />
							)}
							{ModuleList()}
						</div>
					)}
				</>
			)}
		</PageWithSidebar>
	);
}
