
import { useState, useEffect } from "react";
import { useLogin } from "../../Hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoginBackground from "../../assets/img/login-bg.png";
import { apiUrl, emailRegex, passwordRegex } from "../../Configs";
import { useUserApi } from "../../Hooks";
import { ReactComponent as DenteDeLeaoIcon } from "../../assets/img/icons/dente-de-leao.svg";
import { ReactComponent as PlaymoveAcademiaIcon } from "../../assets/img/icons/playmove-academia.svg";
import TextInput from "../Shared/TextInput";
import ForgotPasswordModal from "./ForgotPasswordModal";
import InlineAlert from "../Shared/InlineAlert";
import axios from "axios";
export default function Login() {
  const [buttonsEnabled, setButtonsEnabled] = useState(true);
  const [email, setEmail] = useState("");
  const [updatePassword, setUpdatePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useState({});
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState({
    confirmation: false,
    regex: false,
  });
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const { tokenExists, login } = useLogin();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const userApi = useUserApi();

  useEffect(() => {
    axios.get(apiUrl);

    if (tokenExists() && !updatePassword) {
      navigate("/home");
    }

    if (searchParams.get("senhaAtualizada")) {
      setAlert({type: "success", message: "Senha atualizada com sucesso"});
  }}
  , [searchParams]);

  const handleLogin = async (event) => {
    event.preventDefault();
    
    if(!buttonsEnabled)
      return;

    setButtonsEnabled(false);
    setAlert({});
    setLoading(true);
    const loginResult = await login(email, password);
    await new Promise((r) => setTimeout(r, 500));

    if (loginResult.success) {
      if (loginResult.shouldUpdatePassword) setUpdatePassword(true);
      else navigate("/home");
    } else setAlert({type: "error", message: "Login ou senha inválidos"});

    setLoading(false);
    setButtonsEnabled(true);
  };

  const handleSubmitNewPassword = async () => {
    setPasswordError({ confirmation: false, regex: false });
    var tempPasswordError = { confirmation: false, regex: false };
    setLoading(true);
    await new Promise((r) => setTimeout(r, 500));

    if (!passwordRegex.test(newPassword))
      tempPasswordError = { ...tempPasswordError, regex: true };
    else if (newPassword !== newPasswordConfirmation)
      tempPasswordError = { ...tempPasswordError, confirmation: true };

    if (!tempPasswordError.regex && !tempPasswordError.confirmation) {
      const updatePasswordResult = await userApi.updateUserPassword(
        email,
        password,
        newPassword
      );
      if (updatePasswordResult.status === 200) {
        navigate("/home");
      } else setPasswordError({ regex: true, confirmation: false });
    }

    setPasswordError(tempPasswordError);
    setLoading(false);
  };

 
  const PlaymoveAcademiaLogo = () => {
    return (
      <div className="flex flex-row">
        <div className="self-center pr-3">
          <PlaymoveAcademiaIcon className="fill-playmove-blue h-14 w-14" />
        </div>
        <div className="flex flex-col -mb-2">
          <b className="mt-auto -mb-2 mr-auto text-playmove-gray font-dosisbold text-sm">playmove</b>
          <b className="mt-automr-auto text-playmove-blue font-dosisbold text-[1.75rem]">
            ACADEMIA
          </b>
        </div>
      </div>
    );
  };

  const PlaymoveFooterLogo = () => {
    return (
      <div className="m-auto flex flex-row items-center">
        <DenteDeLeaoIcon className="h-[20px] fill-white" />{" "}
        <p className="ml-1 text-white tracking-wider font-bold text-lg">
          playmove
        </p>
      </div>
    );
  };

  const handleForgotPasswordClick = () => {
    setShowForgotPasswordModal(true);
    }

  const LoginForm = () => {
    return (
      <form className="h-full flex flex-col" 
      onSubmit={handleLogin}>
        <TextInput
          id="email"
          label="Login"
          placeholder="seu e-mail"
          type="text"
          value={email}
          onChangeText={(e) => setEmail(e.target.value)}
          onClick={() => setAlert({})}
          error={alert.type === "error"}
          className="mt-5"
          size="sm"
        />
        <TextInput
          id="password"
          label="Senha"
          placeholder="sua senha"
          type="password"
          value={password}
          onChangeText={(e) => setPassword(e.target.value)}
          onClick={() => setAlert({})}
          error={alert.type === "error"}
          className="mt-5"
          size="sm"
        />

        <button
          type="submit"
          className={`font-dosisbold mt-5 w-full bg-playmove-green rounded-xl p-2 text-lg text-playmove-blue transition-all ease-in-out border-playmove-darkgreen border-b-[3px] ${buttonsEnabled && 'hover:bg-playmove-yellow'} ${
            loading && "cursor-wait"
          }`}
        >
          Entrar
        </button>
        
        <InlineAlert
                    alert={alert}
                    visible={alert.type === "error" || alert.type === "success"}
                  />

        <p
          className="text-playmove-blue font-dosisbold text-sm text-center my-auto cursor-pointer"
          onClick={handleForgotPasswordClick}
        >
          Esqueci minha senha
        </p>
      </form>
    );
  };

  const UpdatePasswordForm = () => {
    return (
      <div>
        <div
          className={`text-center text-sm rounded-lg mt-2 mb-2 font-bold py-1 transition-opacity duration-200 ease-in-out`}
        >
          No primeiro acesso, você precisa criar uma nova senha.
        </div>
        <div
          className={`text-center text-sm border-l-2 rounded-lg mb-2 px-3 font-bold py-1 transition-colors duration-200 ease-in-out ${
            passwordError.regex
              ? "bg-playmove-lightred border-playmove-red"
              : "bg-green-100 border-green-500"
          }`}
        >
          A senha nova deve conter no mínimo 6 caracteres,
          <br /> uma letra e um número.
        </div>
        <TextInput
          id="newPassword"
          label="Nova senha"
          placeholder=""
          type="password"
          value={newPassword}
          onChangeText={(e) => setNewPassword(e.target.value)}
          onClick={() =>
            setPasswordError({ confirmation: false, regex: false })
          }
          error={passwordError.confirmation || passwordError.regex}
          className="mt-1"
          size="sm"
        />
        <TextInput
          id="newPasswordConfirmation"
          label="Confirme a nova senha"
          placeholder=""
          type="password"
          value={newPasswordConfirmation}
          onChangeText={(e) => setNewPasswordConfirmation(e.target.value)}
          onClick={() =>
            setPasswordError({ confirmation: false, regex: false })
          }
          error={passwordError.confirmation || passwordError.regex}
          className="mt-5"
          size="sm"
        />

        <button
          onClick={handleSubmitNewPassword}
          type="submit"
          className={`mt-5 w-full bg-playmove-green rounded-xl p-2 text-lg font-bold text-playmove-blue transition-all ease-in-out border-playmove-darkgreen border-b-[3px] hover:bg-playmove-yellow ${
            loading && "cursor-wait"
          }`}
        >
          Confirmar
        </button>

        <div
          className={`mt-3 text-center bg-playmove-lightred border-l-2 border-playmove-red rounded-lg mb-2 font-bold py-1 transition-all duration-200 ease-in-out ${
            passwordError.confirmation ? "opacity-100" : "invisible opacity-0"
          }`}
        >
          As senhas não coincidem!
        </div>
      </div>
    );
  };

  return (
    <main
      className={`bg-cover bg-fixed bg-center w-screen h-screen overflow-x-hidden pt-10 lg:pt-20 px-6 lg:px-24`}
      style={{ backgroundImage: `url(${LoginBackground})` }}
    >
      <div
        className={`flex flex-col min-w-fit lg:w-[40%] h-[100%] ${
          loading ? "cursor-wait opacity-50" : "opacity-100"
        }`}
      >
        <div className="bg-white h-[80%] overflow-y-auto rounded-tl-lg rounded-tr-3xl pt-12 px-12 lg:px-24 flex flex-col">
          <PlaymoveAcademiaLogo />
          <ForgotPasswordModal isOpen={showForgotPasswordModal} onClose={() => setShowForgotPasswordModal(false)}/>
          {updatePassword ? UpdatePasswordForm() : LoginForm()}
        </div>
        <div className="flex bg-playmove-blue h-[20%] rounded-bl-3xl rounded-br-[4rem] mb-6 md:mb-20">
          <PlaymoveFooterLogo />
        </div>
      </div>
    </main>
  );
}
