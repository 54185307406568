import PageWithSidebar from "../PageWithSidebar";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FilterButton from "../Shared/Buttons/FilterButton";
import TrilhasConcluidas from "./TrilhasConcluidas";
import UsuariosCriados from "./UsuariosCriados";
import UsuariosPorMunicipio from "./UsuariosPorMunicipio";
import ProgressosCursos from "./ProgressosCursos";

export default function Relatorios({ section }) {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 500));

      setLoading(false);
    };

    loadData();
  }, []);

  return (
    <PageWithSidebar loading={loading} additionalClasses="flex flex-col text-playmove-darkgray" allowedRoles={['Admin', 'PlaymoveAdmin']}>
      <div className="flex flex-col">
        <div className="flex flex-row h-fit pb-5 border-b-gray-300 border-b-[3px]">
          <div className="flex flex-col">
            <p className="mt-auto text-xl font-dosisbold">Relatórios</p>
            <p className="mb-auto text-2xl font-bold">{section}</p>
          </div>
        </div>

        <div className="flex flex-wrap mt-3 justify-center md:justify-start md:flex-row gap-y-4 gap-x-3 mb-auto">
          <FilterButton
            text="Progresso por Curso"
            selected={section === "Progresso por Curso"}
            onClick={() => navigate('/relatorios/progressosCursos')}
          />
          {/* <FilterButton
            text="Trilhas Concluídas"
            selected={section === "Trilhas Concluídas"}
            onClick={() => navigate('/relatorios/trilhasConcluidas')}
          />
          <FilterButton
            text="Usuários Criados"
            selected={section === "Usuários Criados"}
            onClick={() => navigate('/relatorios/usuariosCriados')}
          />
          <FilterButton
            text="Usuários por município"
            selected={section === "Usuários por município"}
            onClick={() => navigate('/relatorios/usuariosPorMunicipio')
            }
          /> */}
        </div>

        {
          section === "Trilhas Concluídas" ? <TrilhasConcluidas /> :
          section === "Usuários Criados" ? <UsuariosCriados /> :
          section == "Progresso por Curso" ? <ProgressosCursos /> :
          section === "Usuários por município" && <UsuariosPorMunicipio />
        }

      </div>
    </PageWithSidebar>
  );
}
