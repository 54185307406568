import PageWithSidebar from "../PageWithSidebar";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FilterButton from "../Shared/Buttons/FilterButton";
import Escolas from "./Escolas";
import Turmas from './Turmas';
import Alunos from "./Alunos";

export default function Cadastros({section}) {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 500));

      setLoading(false);
    };

    loadData();
  }, []);

  return (
    <PageWithSidebar loading={loading} additionalClasses="flex flex-col text-playmove-darkgray" allowedRoles={['Admin','PlaymoveAdmin']}>
      <div className="flex flex-col">
        <div className="flex flex-row h-fit pb-5 border-b-gray-300 border-b-[3px]">
          <div className="flex flex-col">
            <p className="mt-auto text-xl font-dosisbold">Cadastros</p>
            <p className="mb-auto text-2xl font-bold">{section}</p>
          </div>
        </div>

        <div className="flex flex-wrap mt-3 justify-center md:justify-start md:flex-row gap-y-4 gap-x-3 mb-auto">
          <FilterButton
            text="escolas"
            selected={section === "Escolas"}
            onClick={() => navigate('/cadastros/escolas')}
          />
          <FilterButton
            text="turmas"
            selected={section === "Turmas"}
            onClick={() => navigate('/cadastros/turmas')}
          />
          <FilterButton
            text="alunos"
            selected={section === "Alunos"}
            onClick={() => navigate('/cadastros/alunos')}
          />
         
        </div>
        {section === "Escolas" ? <Escolas/> :
        section === "Turmas" ? <Turmas/> :
        section === "Alunos" && <Alunos/>}
        
      </div>
    </PageWithSidebar>
  );
}
