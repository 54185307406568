import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect } from "react";
import SaveButton from "../../../../Shared/Buttons/SaveButton";
import CancelButton from "../../../../Shared/Buttons/CancelButton";
import InlineAlert from "../../../../Shared/InlineAlert";
import { useStudentApi } from "../../../../../Hooks";
import { ReactComponent as SpinnerIcon } from "../../../../../assets/img/icons/spinner.svg";

export default function ListStudentFormModal({
  isOpen,
  onClose,
  onSuccess
}) {
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({});
  const studentApi = useStudentApi();
  const [buttonsEnabled, setButtonsEnabled] = useState(true);
  const [listStudentFile, setListStudentFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setListStudentFile(selectedFile);
  };

  const clearAlert = () => {
    setAlert({ type: "", message: "" });
  };

  const handleSave = async () => {
    setButtonsEnabled(false);
    setAlert({});

    var alerts = [];

    if (!listStudentFile) {
      alerts.push("Por favor, selecione um arquivo para importar.");
    } else {
      const fileExtension = listStudentFile.name && listStudentFile.name.split(".").pop();
      if (fileExtension !== "csv") {
        alerts.push("O arquivo deve estar no formato .csv");
      }
    }

    if (!alerts.length) {
      setLoading(true);
      const formData = new FormData();
      formData.append("File", listStudentFile, listStudentFile.name);

      try {
        const response = await studentApi.uploadStudent(formData);
        await handleRequestResult(response);
      } catch (error) {
        setAlert({ type: "error", message: alerts.join("<br/>") });
      }
    } else {
      setAlert({ type: "error", message: alerts.join("<br/>") });
    }

    setButtonsEnabled(true);
    setLoading(false);
  };

  const handleRequestResult = async (request) => {
    if (request.status === 200) {
      setLoading(false);
      setAlert({
        type: "success",
        message: `Lista de alunos ${"criada"} com sucesso`,
      });
      await new Promise((r) => setTimeout(r, 1000));
      onSuccess();
      onClose();
    } else setAlert({
      type: "error",
      message: request.response.data[0]
    });
    await new Promise((r) => setTimeout(r, 100));
    onSuccess();
  };

  useEffect(() => {
    if (!isOpen) return;
    setLoading(true);
    setAlert({});
    setLoading(false);
    setButtonsEnabled(true);
  }, [isOpen]);

  const downloadExampleFile = () => {
    const exampleFile = { name: "exemplo.csv", content: "Nome;Email;UF;Cidade;Escola;Turma" };
    const blob = new Blob([exampleFile.content], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = exampleFile.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => { return }} >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div
            className={`flex min-h-full items-center justify-center p-4 text-center ${
              loading && "cursor-wait"
            }`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full flex flex-col max-w-md h-[21rem] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg leading-6 font-bold">
                  {"Importar"} Lista de Alunos
                </Dialog.Title>

                {loading ? <SpinnerIcon className="m-auto w-24 h-24" /> : <>
                  <input type="file" id="fileSelect" accept=".csv"
                    onChange={handleFileChange}
                    className="m-auto flex flex-row items-center gap-x-1"
                    onClick={clearAlert}
                    error={alert.type === "error" ? "true" : undefined}
                    size="sm"
                  />
                  <button
                    className="bg-playmove-yellow hover:bg-playmove-green hover:border-playmove-green hover:text-white px-4 py-2 rounded-md mt-2"
                    onClick={downloadExampleFile}
                  >
                    Modelo Exemplo
                  </button>
                </>}

                <InlineAlert
                  alert={alert}
                  visible={alert.type === "error" || alert.type === "success"}
                />
                {(!alert.type) && (
                  <p className="text-center mt-5 font-bold">
                    A senha será enviada por e-mail aos alunos da lista.
                  </p>
                )}

                <div className="mt-auto flex flex-row ml-auto gap-x-3">
                  <CancelButton onClick={onClose} enabled={buttonsEnabled} />
                  <SaveButton
                    onClick={handleSave}
                    enabled={buttonsEnabled}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
