import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Fragment, useEffect, useRef, useState, useMemo } from "react";
import PageWithSidebar from "./../../../PageWithSidebar/index";
import InlineAlert from "./../../../Shared/InlineAlert/index";
import TextInput from "./../../../Shared/TextInput/index";
import {
  useSchoolApi,
  useStateApi,
  useCoursesApi,
  useClassApi,
  useTrailsApi
} from "./../../../../Hooks";
import DropdownInput from "../../../Shared/DropdownInput";
import {
  truncate,
  formatMinutesIntoHoursAndMinutes,
  isNullOrWhitespace
} from "../../../../Utils/TextUtils";
import { Table } from "../../../Shared/Table";
import AddItemButton from "./../../../Shared/Buttons/AddItemButton/index";
import DeleteItemButton from "./../../../Shared/Buttons/DeleteItemButton/index";
import SearchBar from "../../../Shared/SearchBar";
import CancelButton from "../../../Shared/Buttons/CancelButton";
import SaveButton from "../../../Shared/Buttons/SaveButton";
import { ReactComponent as SpinnerIcon } from "../../../../assets/img/icons/spinner.svg";

export default function ClassForm({ edit = false }) {
  const [loading, setLoading] = useState(true);
  const [loadingCourses, setLoadingCourses] = useState(true);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [buttonsEnabled, setButtonsEnabled] = useState(true);
  const [classForm, setClassForm] = useState({
    name: "",
    school: null,
    city: null,
    state: null,
    courses: [],
  });
  const [alert, setAlert] = useState({});
  const { id } = useParams();
  const schoolApi = useSchoolApi();
  const classApi = useClassApi();
  const stateApi = useStateApi();
  const coursesApi = useCoursesApi();
  const trailsApi = useTrailsApi();
  const [schools, setSchools] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [courses, setCourses] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 300));
      const loadedCourses = await loadCourses();

      if(edit)
        await loadExistingClass(loadedCourses);
      else
        await loadStates();

      setLoading(false);
    };
    loadData();
  }, [id]);

  const loadExistingClass = async (loadedCourses) => {
    const getClass = await classApi.class(id);
    const state = { value: getClass.stateId, label: getClass.stateAbbreviation };
    const city = { value: getClass.cityId, label: getClass.cityName };
    const school = { value: getClass.schoolId, label: getClass.schoolName };
    const selectedCourses = getClass.availableCourses.concat(getClass.availableTrails).map((course) => {
      const courseData = loadedCourses.find((c) => c.id === course.id && c.type === course.type);
      if (courseData) {
        loadedCourses.splice(loadedCourses.indexOf(courseData), 1);
        return courseData;
      }
    }).filter(function( course ) {
      return course !== undefined;
   });
      
    const classForm = {
      id: id,
      name: getClass.name,
      school: school,
      city: city,
      state: state,
      courses: selectedCourses,
    };
    setCourses(loadedCourses); 
    setClassForm(classForm);    
  }

  const loadCourses = async (search = "") => {
    setLoadingCourses(true);
    await new Promise((r) => setTimeout(r, 500));
    const courseList = await coursesApi.courseList(0, 0, search);    
    const trailList = await trailsApi.trailList(0, 0, search);
    const courses = courseList.items.concat(trailList.items);

    classForm.courses.forEach((course) => {
      const existingCourse = courses.find((c) => c.id === course.id && c.type === course.type);
      if (existingCourse)
        courseList.items.splice(courses.indexOf(existingCourse), 1);
    });
    

    setCourses(courses);
    setLoadingCourses(false);
    return courses;
  };

  const loadStates = async () => {
    const getStates = await stateApi.stateList();
    const states = getStates.map((state) => ({
      value: state.id,
      label: state.abbreviation,
    }));
    setStates(states);

  };

  const loadCities = async (state) => {
    if (state === null) {
      setCities([]);
      setClassForm({ ...classForm, city: null });
      return;
    }

    const getCities = await stateApi.cityList(state.value);
    const cities = getCities.map((city) => ({
      value: city.id,
      label: city.name,
    }));
    setCities(cities);
  };

  const loadSchools = async (city) => {
    if (city === null) {
      setSchools([]);
      setClassForm({ ...classForm, school: null });
      return;
    }

    const getSchools = await schoolApi.schoolList(
      0,
      0,
      "",
      city.value,
      classForm.state.value
    );
    const schools = getSchools.items.map((school) => ({
      value: school.id,
      label: school.name,
    }));
    setSchools(schools);
  };

  const handleStateChange = async (e) => {
    clearAlert();
    setClassForm({ ...classForm, state: e, city: null, school: null });
    loadCities(e);
  };

  const handleCityChange = async (e) => {
    clearAlert();
    setClassForm({ ...classForm, city: e, school: null });
    loadSchools(e);
  };

  const handleSchoolChange = async (e) => {
    clearAlert();
    setClassForm({ ...classForm, school: e });
  };

  const clearAlert = () => {
    setAlert({});
  };

  const handleAddCourse = (e) => {
    setLoadingCourses(true);
    const course = courses.find((course) => course.id === e);
    courses.splice(courses.indexOf(course), 1);

    const newCourses = [...classForm.courses, course];
    setCourses(courses);
    setClassForm({ ...classForm, courses: newCourses });
    setLoadingCourses(false);
  };

  const handleRemoveCourse = (e) => {
    setLoadingCourses(true);
    const course = classForm.courses.find((course) => course.id === e);
    classForm.courses.splice(classForm.courses.indexOf(course), 1);

    const newCourses = [...courses, course];
    setCourses(newCourses);
    setClassForm({ ...classForm, courses: classForm.courses });
    setLoadingCourses(false);
  };

  const handleSaveClass = async (e) => {
    setButtonsEnabled(false);
    setLoadingRequest(true);
    await new Promise((r) => setTimeout(r, 500));

    if (!validateFields()) return;

    const { id, name, school, courses } = classForm;
    const newClass = {
      id,
      name,
      schoolId: school.value,
      availableCoursesIds: courses.filter((course) => course.type === "Course").map((course) => course.id),
      availableTrailsIds: courses.filter((course) => course.type === "Trail").map((course) => course.id),
    };

    const request = edit
      ? await classApi.updateClass(newClass)
      : await classApi.createClass(newClass);

    await handleRequestResponse(request);
    setLoadingRequest(false);
    setButtonsEnabled(true);
  };

  const handleRequestResponse = async (request) => {
    if (request.status === 200) {
      setAlert({
        type: "success",
        message: `Turma ${edit ? "atualizada" : "criada"} com sucesso!`,
      });
      await new Promise((r) => setTimeout(r, 1000));
      navigate("/cadastros/turmas");
    } else setAlert({ type: "error", message: request.response.data[0] });
  };

  const validateFields = () => {
    const { name, school, state, city } = classForm;
    var alerts = [];

    if (edit && isNullOrWhitespace(id)) navigate("/cadastros/turmas");

    if (isNullOrWhitespace(name)) alerts.push("O campo nome é obrigatório");

    if (state === null) alerts.push("O campo estado é obrigatório");

    if (city === null) alerts.push("O campo cidade é obrigatório");

    if (school === null) alerts.push("O campo escola é obrigatório");

    if (alerts.length) {
      setLoadingRequest(false);
      setButtonsEnabled(true);
      setAlert({ type: "error", message: alerts.join("<br/>") });
    }

    return !alerts.length;
  };

  const courseTableColumns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        show: false,
      },
      {
        Header: "CourseCount",
        accessor: "courseCount",
        show: false,
      },
      {
        Header: "Tipo",
        accessor: "type",
        Cell: ({ value }) => (
          <p className="font-bold">
            {value === "Course" ? "Curso" : "Trilha"}
          </p>
        ),
        width: "10%",
      },
      {
        Header: "Título",
        accessor: "title",
        Cell: ({ value }) => (
          <p data-tip={value} className="font-bold">
            {truncate(value, 50)}
          </p>
        ),
        width: "40%",
      },

      {
        Header: "Módulos/Cursos",
        accessor: "moduleCount",
        Cell: ({ row }) => (<>
          <p>{row.values.moduleCount > 0 && `${row.values.moduleCount} módulo${row.values.moduleCount > 1 ? "s" : ""}`}</p>
          <p>{row.values.courseCount > 0 && `${row.values.courseCount} curso${row.values.courseCount > 1 ? "s" : ""}`}</p>
          </>
        ),
        width: "30%",
      },
      {
        Header: "Duração",
        accessor: "duration",
        Cell: ({ value }) => (
          <p>{formatMinutesIntoHoursAndMinutes(value)} hora (s)</p>
        ),
        width: "30%",
      },
      {
        Header: " ",
        accessor: "",
        Cell: ({ row }) => (
          <div className="ml-auto flex flex-row gap-x-20">
            {!classForm.courses.find(
              (course) => course.id === row.values.id
            ) ? (
              <AddItemButton onClick={() => handleAddCourse(row.values.id)} />
            ) : (
              <DeleteItemButton
                onClick={() => handleRemoveCourse(row.values.id)}
              />
            )}
          </div>
        ),
        width: "10%",
      },
    ],
    [courses, classForm]
  );

  return (
    <PageWithSidebar loading={loading} additionalClasses="overflow-x-hidden">
      <div className="flex flex-col">
        <div className="flex flex-col gap-y-2 md:flex-row h-fit pb-5 border-b-gray-300 border-b-[3px]">
          <div className="flex md:flex-row">
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="text-2xl cursor-pointer self-center border border-black rounded-full py-3 px-4"
            onClick={() => navigate("/cadastros/turmas")}
          />
          <div className="ml-5 flex flex-col">
            <p className="mt-auto text-xl font-bold">
              {edit ? "Edição" : "Nova"}
            </p>
            <p className="mb-auto text-2xl font-dosisbold">Turma</p>
          </div>
          </div>
          <div className="mx-auto md:ml-auto md:mx-0">
            {loadingRequest && <SpinnerIcon className="h-14 w-14" />}
          </div>
          <div className="mx-auto md:ml-auto md:mx-0 flex flex-row gap-3 h-12 self-center">
            <CancelButton
              onClick={() => navigate("/cadastros/turmas")}
              enabled={buttonsEnabled}
            />
            <SaveButton onClick={handleSaveClass} enabled={buttonsEnabled} />
          </div>
        </div>
        <div className="flex flex-row pb-5 border-b-gray-300 border-b-[3px]">
          <div className="flex flex-col w-96">
            <TextInput
              id="nome"
              label="Nome"
              placeholder="nome da turma"
              type="text"
              className="mt-5"
              value={classForm.name}
              onChangeText={(e) =>
                setClassForm({ ...classForm, name: e.target.value })
              }
              onClick={clearAlert}
              error={alert.type === "error"}
              size="sm"
            />

            <div className="mt-3 flex flex-row gap-x-3">
              <DropdownInput
                label="UF"
                items={states}
                value={classForm.state}
                error={alert.type === "error"}                
                disabled={edit}
                inputClassName="w-16"
                placeholder="UF"
                onChange={handleStateChange}
              />
              <DropdownInput
                label="Cidade"
                items={cities}
                value={classForm.city}
                error={alert.type === "error"}                
                disabled={edit}                
                className="grow"
                placeholder="cidade"
                emptyMessage="Selecione um estado"
                onChange={handleCityChange}
              />
            </div>

            <DropdownInput
              label="Escola"
              items={schools}
              value={classForm.school}
              error={alert.type === "error"}
              disabled={edit}
              className="mt-3"
              placeholder="escola"
              emptyMessage="Selecione uma cidade com escolas"
              onChange={handleSchoolChange}
            />

            <InlineAlert
              alert={alert}
              visible={alert.type === "error" || alert.type === "success"}
            />
          </div>
        </div>
        {classForm.courses.length > 0 && (
          <div className="flex flex-col pb-5 mt-5 border-b-gray-300 border-b-[3px]">
            <p className="mb-auto text-2xl font-dosisbold">
              Cursos disponíveis para essa turma
            </p>
            <div className="-ml-12 w-screen md:w-full md:ml-0">          
            <Table
              className="w-full mt-3"
              columns={courseTableColumns}
              data={classForm.courses}
              loadingContent={false}
            />
            </div>
          </div>
        )}
        <div className="flex flex-col pb-5 mt-5 border-b-gray-300 border-b-[3px]">
          <div className="flex flex-col gap-y-2 md:flex-row">
            <p className="mb-auto text-2xl font-dosisbold">Adicionar Cursos</p>
            <SearchBar
              value={search}
              onChangeText={(e) => setSearch(e.target.value)}
              onClick={() => loadCourses(search)}
              placeholder="Buscar por título..."
              className="mx-auto md:ml-auto md:mx-0"
            />
          </div>
          <div className="-ml-12 w-screen md:w-full md:ml-0">
          <Table
            className="w-full mt-3"
            columns={courseTableColumns}
            data={courses}
            loadingContent={loadingCourses} />
            </div>          
        </div>
      </div>
    </PageWithSidebar>
  );
}
