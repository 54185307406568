
import {ReactComponent as VeryBadIcon} from '../../../assets/img/icons/rating/verybad.svg';
import {ReactComponent as BadIcon} from '../../../assets/img/icons/rating/bad.svg';
import {ReactComponent as AverageIcon} from '../../../assets/img/icons/rating/average.svg';
import {ReactComponent as GoodIcon} from '../../../assets/img/icons/rating/good.svg';
import {ReactComponent as VeryGoodIcon} from '../../../assets/img/icons/rating/verygood.svg';

function RateButton({selected, onClick, rating}) {
  return (<div className="flex flex-row md:flex-col gap-x-2">
    <button
      className={`flex flex-col w-[5.25rem] h-[3.375rem] md:w-[6.25rem] md:h-[4.375rem] rounded-[0.625rem] hover:border hover:border-playmove-mediumblue ${selected ? 'bg-playmove-mediumblue' : 'bg-playmove-lightblue'}`}
      onClick={onClick}
    >        
      <rating.icon className={`m-auto w-8 md:w-auto ${selected ? "fill-white" : "fill-playmove-blue"}`}/>
    </button>
    <span className="self-center md:self-auto md:my-0 mt-2 font-bold text-playmove-lightgray">{rating.name}</span>
    </div>
  );
}

const ratings = [
    {name: "Péssimo", icon: VeryBadIcon}, 
    {name: "Ruim", icon: BadIcon},
    {name: "Médio", icon: AverageIcon},
    {name: "Bom", icon: GoodIcon},
    {name: "Muito bom", icon: VeryGoodIcon}];

export default function RatingCategory
({value, onChange, category}) {
    return (<div className="h-full flex flex-col">
    <p className="text-left text-lg font-bold text-playmove-darkgray">{category}</p>
    <div className="mt-[1rem] flex flex-col-reverse md:flex-row gap-x-[2.5rem] gap-y-[1rem] place-content-around">
        {ratings.map((rating, index) => {
            return (<RateButton
                key={index}
                selected={(value) === (index + 1)}
                onClick={() => onChange(index + 1)}
                rating={rating}
            />);
        }
        )}
    </div>


    </div>);
}