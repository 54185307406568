import {createContext, useState} from "react";
import AvatarPlaceholder from "../../assets/img/avatar_placeholder.png";

const UserInfoContext = createContext({userInfo: {name: "", id: "", avatar: AvatarPlaceholder}});

const UserInfoContextProvider = ({children}) => {
    const [userInfo, setUserInfo] = useState({name: "", id: "", avatar: AvatarPlaceholder});

    return (
        <UserInfoContext.Provider value={[userInfo, setUserInfo]}>
            {children}
        </UserInfoContext.Provider>
    );
};

export {UserInfoContext, UserInfoContextProvider}

