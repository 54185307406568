import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect } from "react";
import TextInput from "../../../Shared/TextInput";
import SaveButton from "../../../Shared/Buttons/SaveButton";
import CancelButton from "../../../Shared/Buttons/CancelButton";
import InlineAlert from "../../../Shared/InlineAlert";
import { useSchoolApi, useStateApi } from "../../../../Hooks";
import DropdownInput from "../../../Shared/DropdownInput/index";
import { isNullOrWhitespace } from "../../../../Utils/TextUtils";
import { ReactComponent as SpinnerIcon } from "../../../../assets/img/icons/spinner.svg";

export default function SchoolFormModal({
  isOpen,
  onClose,
  onSuccess,
  school = null,
}) {
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({});
  const [schoolForm, setSchoolForm] = useState({
    id: "",
    name: "",
    city: null,
    state: null,
  });
  const edit = school !== null;
  const schoolApi = useSchoolApi();
  const stateApi = useStateApi();
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [buttonsEnabled, setButtonsEnabled] = useState(true);

  const loadStates = async () => {
    const getStates = await stateApi.stateList();
    const states = getStates.map((state) => ({
      value: state.id,
      label: state.abbreviation,
    }));
    setStates(states);
  };

  const loadCities = async (state) => {
    if (state === null) {
      setCities([]);
      setSchoolForm({ ...schoolForm, city: null });
      return;
    }

    const getCities = await stateApi.cityList(state.value);
    const cities = getCities.map((city) => ({
      value: city.id,
      label: city.name,
    }));
    setCities(cities);
  };

  const handleStateChange = async (e) => {
    clearAlert();
    setSchoolForm({ ...schoolForm, state: e, city: null });
    loadCities(e);
  };

  const handleCityChange = async (e) => {
    clearAlert();
    setSchoolForm({ ...schoolForm, city: e });
  };

  const clearAlert = () => {
    setAlert({ type: "", message: "" });
  };

  const handleSave = async () => {
    setButtonsEnabled(false);
    setAlert({});
    var alerts = [];

    if (isNullOrWhitespace(schoolForm.name)) {
      alerts.push("O campo nome é obrigatório");
    }

    if (schoolForm.state === null) {
      alerts.push("O campo estado é obrigatório");
    }

    if (schoolForm.city === null) {
      alerts.push("O campo cidade é obrigatório");
    }

    if (!alerts.length) {
      setLoading(true);
      await new Promise((r) => setTimeout(r, 500));
      const data = {
        id: schoolForm.id,
        name: schoolForm.name,
        cityId: schoolForm.city.value,
      };

      const request = edit
        ? await schoolApi.updateSchool(data)
        : await schoolApi.createSchool(data);

      await handleRequestResult(request);
    } else setAlert({ type: "error", message: alerts.join("<br/>") });

    setButtonsEnabled(true);
    setLoading(false);
  };

  const handleRequestResult = async (request) => {
    if (request.status === 200) {
      setLoading(false);
      setAlert({
        type: "success",
        message: `Escola ${edit ? "atualizada" : "criada"} com sucesso`,
      });
      await new Promise((r) => setTimeout(r, 1000));
      onSuccess();
      onClose();
    } else setAlert({ type: "error", message: request.response.data[0] });
  };

  useEffect(() => {
    if (!isOpen) return;
    setLoading(true);

    if (school !== null) {
      setSchoolForm({
        id: school.id,
        name: school.name,
        city: { label: school.cityName, value: school.cityId },
        state: { label: school.stateAbbreviation, value: school.stateId },
      });
    } else {
      setSchoolForm({ id: "", name: "", city: null, state: null });
    }

    loadStates();
    setCities([]);
    setAlert({});
    setLoading(false);
    setButtonsEnabled(true);
  }, [isOpen]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {return}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div
            className={`flex min-h-full items-center justify-center p-4 text-center ${
              loading && "cursor-wait"
            }`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full flex flex-col max-w-md h-96 transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg leading-6 font-bold">
                  {edit ? "Editar" : "Criar"} Escola
                </Dialog.Title>

                {loading ? <SpinnerIcon className="m-auto w-24 h-24"/> : <>
                <TextInput
                  id="nome"
                  label="Nome"
                  placeholder="nome da escola"
                  type="text"
                  className="mt-5"
                  value={schoolForm.name}
                  onChangeText={(e) =>
                    setSchoolForm({ ...schoolForm, name: e.target.value })
                  }
                  onClick={clearAlert}
                  error={alert.type === "error"}
                  size="sm"
                />
                <div className="mt-3 flex flex-row gap-x-3">
                  <DropdownInput
                    label="UF"
                    disabled={edit}
                    items={states}
                    value={schoolForm.state}
                    error={alert.type === "error"}
                    inputClassName="w-16"
                    placeholder="UF"
                    onChange={handleStateChange}
                  />
                  <DropdownInput
                    label="Cidade"
                    disabled={edit}
                    items={cities}
                    value={schoolForm.city}
                    error={alert.type === "error"}
                    className="grow"
                    placeholder="cidade"
                    emptyMessage="Selecione um estado"
                    onChange={handleCityChange}
                  />
                </div>
                </>}
                <InlineAlert
                  alert={alert}
                  visible={alert.type === "error" || alert.type === "success"}
                />

                <div className="mt-auto flex flex-row ml-auto gap-x-3">
                  <CancelButton onClick={onClose} enabled={buttonsEnabled} />
                  <SaveButton onClick={handleSave} enabled={buttonsEnabled} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
