import { Transition } from '@headlessui/react'
import React, { useState, useEffect } from 'react'

export default function InlineAlert({alert, className, visible}) {
    const [message, setMessage] = useState(alert.message);
    const [type, setType] = useState(alert.type);
    const color = type === "success" ? "bg-green-100" : "bg-playmove-lightred";
    const borderColor = type === "success" ? "border-green-500" : "border-playmove-red";


    useEffect(() => {
        if(alert.message)
            setMessage(alert.message);
    }
    , [alert.message]);

    
    useEffect(() => {
        if(alert.type)
            setType(alert.type);
    }
    , [alert.type]);

    return (
        <Transition
            show={visible}
            enter="transition-all duration-600"
            enterFrom="-translate-x-[100vw] opacity-0"
            enterTo="opacity-100"
            leave="transition-all duration-600"
            leaveFrom="opacity-100"
            leaveTo="opacity-0 -translate-x-[100vw]"
        >
        <div
            className={`${className} ${color} ${borderColor} mt-3 text-center b border-l-2 rounded-lg mb-2 overflow-hidden font-bold py-1`}>
            <div dangerouslySetInnerHTML={{__html: message}}></div>
        </div>
        </Transition>
    )
}