import { ReactComponent as PencilIcon } from "../../../../assets/img/icons/pencil.svg";

export default function EditItemButton({ onClick, className }) {
  return (
    <button className={`${className} flex flex-row`} onClick={onClick}>
      <PencilIcon className="self-center w-6 h-6" />{" "}
      <span className="text-base self-center">editar</span>
    </button>
  );
}
