const formatMinutesIntoHoursAndMinutes = (minutes) =>
{            return [3600, 60]
    .reduceRight(
        (p, b) => r => [Math.floor(r / b)].concat(p(r % b)),
        r => [r]
    )(minutes * 60)
    .map(a => a.toString().padStart(2, '0'))
    .slice(0, -1)
    .join(':');
}

const truncate = (input, len) => input.length > len ? `${input.substring(0, len)}...` : input;

const isNullOrWhitespace = (input) => !input || !input.trim();

export { formatMinutesIntoHoursAndMinutes, truncate, isNullOrWhitespace }