import { useEffect, useContext} from "react";
import {ReactComponent as SpinnerIcon} from "../../assets/img/icons/spinner.svg";
import Sidebar from "../Sidebar";
import {useLogin} from "../../Hooks";
import { UserInfoContext } from "../../Contexts/UserInfoContext";
import { useNavigate } from "react-router-dom";

export default function PageWithSidebar({children, additionalClasses, loading, allowedRoles = []}) {
    const {checkIfTokenExpired, refreshToken} = useLogin();
    const [userInfo, setUserInfo] = useContext(UserInfoContext); 
    const userDisallowed = userInfo.id !== "" && allowedRoles.length > 0 && !allowedRoles.includes(userInfo.role);
    const navigate = useNavigate();

    useEffect(() => {
        checkIfTokenExpired();
        const refreshAfter1Second = setTimeout(() => {
            refreshToken();
        }
        , 1000);
        
        const refreshEvery30Seconds = setInterval(() => refreshToken(false), 1000 * 30);
        return () => {clearInterval(refreshEvery30Seconds);
        clearTimeout(refreshAfter1Second);}
    }, []);

    useEffect(() =>
    {       
        if(userDisallowed)       
            navigate("/")        
    }, [userInfo]);

    const renderPage = () => {
        return <>{children}</>;
    };

    const renderLoading = () => {
        return <SpinnerIcon className="m-auto"/>;
    };

    return (
        <div className="flex flex-nowrap h-screen">
            <Sidebar/>
            <main
                className={`overflow-y-auto px-12 py-8 w-screen ${loading ? "flex flex-col" : ""} ${additionalClasses}`}>
                {loading || userDisallowed ? renderLoading() : renderPage()}
            </main>
        </div>
    );
}
