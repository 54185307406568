import { useState, useEffect, useMemo } from "react";
import { ReactComponent as SpinnerIcon } from "../../../assets/img/icons/spinner.svg";
import { useStudentApi, useStateApi, useSchoolApi, useClassApi } from "../../../Hooks";
import { Table } from "../../Shared/Table";
import { Pagination } from "../../Shared/Pagination";
import SearchBar from '../../Shared/SearchBar';
import Dropdown from '../../Shared/Dropdown';
import { truncate } from "../../../Utils/TextUtils";
import DeleteItemButton from '../../Shared/Buttons/DeleteItemButton';
import EditItemButton from '../../Shared/Buttons/EditItemButton';
import CreateButton from '../../Shared/Buttons/CreateButton';
import ConfirmModal from "../../Shared/ConfirmModal";
import StudentFormModal from './StudentFormModal/index';
import ListtudentFormModal from './StudentFormModal/StudentFormModalList/index'

export default function Alunos() {
  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [listData, setListData] = useState({
    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
    totalItems: 0,
  });

  const studentApi = useStudentApi();  
  const stateApi = useStateApi(); 
  const schoolApi = useSchoolApi();
  const classApi = useClassApi();
  const [search, setSearch] = useState("");
  const [states, setStates] = useState([]);
  const [stateFilter, setStateFilter] = useState(null);

  const [cities, setCities] = useState([]);
  const [cityFilter, setCityFilter] = useState(null);
  
  const [schools, setSchools] = useState([]);
  const [schoolFilter, setSchoolFilter] = useState(null);
  
  const [classes, setClasses] = useState([]);
  const [classFilter, setClassFilter] = useState(null);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalLoading, setConfirmModalLoading] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState({message: "", itemId: ""});
  const [confirmModalAlert, setConfirmModalAlert] = useState({});
  const [confirmModalButtonsEnabled, setConfirmModalButtonsEnabled] = useState(true);

  const [showStudentFormModal, setShowStudentFormModal] = useState(false);
  const [showListStudentFormModal, setShowListStudentFormModal] = useState(false);
  const [editStudentData, setEditStudentData] = useState(null);

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 500));
      const getStudents = await studentApi.studentList(
        listData.page,
        listData.itemsPerPage,
        "",
        "",
        ""
      );
      setStudents(getStudents.items);
      setListData({
        ...listData,
        pageCount: getStudents.pageCount,
        totalItems: getStudents.totalItems,
      });
      loadStates();
      setLoading(false);
    };

    loadData();
  }, []);

  const loadStates = async () => {
    const getStates = await stateApi.stateList();
    const states = getStates.map((state) => ({
      value: state.id,
      label: state.name,
    }));
    setStates(states);
  }

  const loadCities = async (state) => {
    if (state === null) {
      setCities([]);
      setCityFilter(null);
      return;
    }
  
    const getCities = await stateApi.cityList(state.value);
    const cities = getCities.map((city) => ({
      value: city.id,
      label: city.name,
    }));
    setCities(cities);
  }

  const loadSchools = async (city) => {
    if(city === null){
      setSchools([]);
      setSchoolFilter(null);
      return;
    }

    const getSchools = await schoolApi.schoolList(0,0,"", city.value, stateFilter.value );
    const schools = getSchools.items.map((school) => ({
      value: school.id,
      label: school.name,
    }));
    setSchools(schools);
  }

  const loadClasses = async (school) => {
    if(school === null){
      setClasses([]);
      setClassFilter(null);
      return;
    }

    const getClasses = await classApi.classList(0,0,"", cityFilter.value, stateFilter.value, school.value );
    const classes = getClasses.items.map((_class) => ({
      value: _class.id,
      label: _class.name,
    }));
    setClasses(classes);
  }

  const reloadData = async (page, cityFilter = null, stateFilter = null, schoolFilter = null, classFilter = null) => {
    try {
      setLoadingData(true);
  
      const cityFilterValue = cityFilter ? cityFilter.value : "";
      const stateFilterValue = stateFilter ? stateFilter.value : "";
      const schoolFilterValue = schoolFilter ? schoolFilter.value : "";
      const classFilterValue = classFilter ? classFilter.value : "";
  
      const getStudents = await studentApi.studentList(
        page,
        listData.itemsPerPage,
        search,
        cityFilterValue,
        stateFilterValue,
        schoolFilterValue,
        classFilterValue
      );
  
      setStudents(getStudents.items);
      setListData({
        ...listData,
        page: page,
        pageCount: getStudents.pageCount,
        totalItems: getStudents.totalItems,
      });
    } catch (error) {
      console.error("Error during data reload:", error);
    } finally {
      setLoadingData(false);
    }
  };
  
  

  const handleSearchClick = async (search) => {    
    reloadData(1, cityFilter, stateFilter, schoolFilter, classFilter);
  }
  
  const handleStateFilterChange = async (e) => {
    setStateFilter(e);
    if (e === null) {
      setCityFilter(null);
      setSchoolFilter(null);
      setClassFilter(null);
      setCities([]);
      setSchools([]);
      setClasses([]);
    } else {
      setCityFilter(null);
      setSchoolFilter(null);
      setClassFilter(null);
      await loadCities(e);
    }
    reloadData(1, null, e, null, null);
  }
  
  
  const handleCityFilterChange = async (e) => {
    setCityFilter(e);
    if (e === null) {
      setSchoolFilter(null);
      setClassFilter(null);
      setClasses([]); 
      setSchools([]); 
    } else {
      setSchoolFilter(null);
      setClassFilter(null);
      await loadSchools(e);
    }
    reloadData(1, e, stateFilter, null, null);
  }
  
  const handleSchoolFilterChange = async (e) => {
    setSchoolFilter(e);
    setClassFilter(null);
    await loadClasses(e);
    reloadData(1, cityFilter, stateFilter, e, null);
  }
  
  const handleClassFilterChange = async (e) => {
    setClassFilter(e);
    reloadData(1, cityFilter, stateFilter, schoolFilter, e);
  }

  const handlePageClick = (event) => {
    reloadData(event.selected + 1, cityFilter, stateFilter, schoolFilter, classFilter);
  };

  const handleDeleteClick = async (name, id) => {
    setConfirmModalAlert({});     
    setConfirmModalButtonsEnabled(true);
    setShowConfirmModal(true);
    setConfirmModalData({message: `Tem certeza que deseja excluir o aluno '${name}'?`, itemId: id, });    
  }  

  const handleEditClick = async (student) => {
    setEditStudentData(student);
    setShowStudentFormModal(true); 
  }  
  
  const clearFilters = () => {
    setCityFilter(null);
    setStateFilter(null);
    setSchoolFilter(null);
    setClassFilter(null);
    setCities([]);
    setSchools([]);
    setClasses([]);
  };
   
  const handleCreateClick = async () => {
    clearFilters();
    setEditStudentData(null);
    setShowStudentFormModal(true);
  };
  
  const handleUploadClick = async () => {
    clearFilters();
    setShowListStudentFormModal(true);
  };   

  const handleDeleteConfirm = async () => {
    setConfirmModalLoading(true);    
    setConfirmModalButtonsEnabled(false);
    await new Promise((r) => setTimeout(r, 500));
    const deleteStudent = await studentApi.deleteStudent(confirmModalData.itemId);
    if(deleteStudent.status === 204){
      setConfirmModalLoading(false);
      setConfirmModalAlert({message: "Aluno excluído com sucesso", type: "success"});
      await new Promise((r) => setTimeout(r, 1000));
      reloadData(listData.page);            
      setShowConfirmModal(false);      
    }
    else {
      setConfirmModalAlert({message: deleteStudent.response.data[0], type: "error"});
      setConfirmModalLoading(false);
      setConfirmModalButtonsEnabled(true);
    }      
  }  

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        show: false
      },
      {
        Header: "Aluno",
        accessor: "name",
        Cell: ({ value }) =><p data-tip={value} className="font-dosisbold text-playmove-darkgray">{truncate(value, 30)}</p>,
        width: "20%"        
      },  
      {
        Header: "ClassId",
        accessor: "classId",
        show: false
      },    
      {
        Header: "Turma",
        accessor: "className",
        Cell: ({ value }) =><p data-tip={value}>{truncate(value, 30)}</p>,
        width: "20%"        
      },
      {
        Header: "SchoolId",
        accessor: "schoolId",
        show: false
      },
      {
        Header: "Escola",
        accessor: "schoolName",
        Cell: ({ value }) =><p data-tip={value}>{truncate(value, 30)}</p>,
        width: "20%"        
      },
      {
        Header: "UF",
        accessor: "stateAbbreviation",
        width: "10%"
      },
      {
        Header: "CityId",
        accessor: "cityId",
        show: false
      },
      {
        Header: "Cidade",
        accessor: "cityName",
        width:"20%"
      },
      {
        Header: "StateId",
        accessor: "stateId",
        show: false
      },
      {
        Header: "E-mail",
        accessor: "email",
        show: false
      },
      {
        Header: " ",
        accessor: "",
        Cell: ({ row }) => (
          <div className="ml-auto w-56 flex flex-row gap-x-20">
            <DeleteItemButton onClick={() => handleDeleteClick(row.values.name, row.values.id)} />
            <EditItemButton onClick={() => handleEditClick(row.values)}/>
          </div>
        ),
      width:"10%"
      },
    ],

    []
  );

  return (
    <div className="flex flex-col">
      {loading ? (
        <SpinnerIcon className="m-auto" />
      ) : (
        <div className="-ml-12 w-screen md:w-full md:ml-0 flex flex-col">
          <div className="flex flex-col flex-wrap items-center md:flex-row mt-3 gap-y-3 gap-x-3">
          <SearchBar value={search} onClick={handleSearchClick} onChangeText={(e) => setSearch(e.target.value)} placeholder={`Buscar por nome de aluno...`} />
          </div>
          <div className="flex flex-col flex-wrap items-center md:flex-row my-3 gap-y-3 gap-x-3">
          <Dropdown items={states} value={stateFilter} placeholder="Filtrar por estado" onChange={handleStateFilterChange}/>
          <Dropdown items={cities} value={cityFilter} placeholder="Filtrar por cidade" emptyMessage="Selecione um estado" onChange={handleCityFilterChange}/>
          <Dropdown items={schools} value={schoolFilter} placeholder="Filtrar por escola" emptyMessage="Selecione uma cidade com escolas" onChange={handleSchoolFilterChange}/>
          <Dropdown items={classes} value={classFilter} placeholder="Filtrar por turma" emptyMessage="Selecione uma escola" onChange={handleClassFilterChange}/>
          <CreateButton onClick={handleCreateClick} className="lg:ml-auto" text="add aluno"/>
          <CreateButton onClick={handleUploadClick} className="lg:ml-mt3" text="add lista alunos"/>
          </div> 
          <StudentFormModal isOpen={showStudentFormModal}  student={editStudentData}  onSuccess={() => reloadData(listData.page)} onClose={() => setShowStudentFormModal(false)} />
          <ListtudentFormModal isOpen={showListStudentFormModal}  onSuccess={() => reloadData(listData.page)} onClose={() => setShowListStudentFormModal(false)} />
          <ConfirmModal isOpen={showConfirmModal} alert={confirmModalAlert} loading={confirmModalLoading} onConfirm={handleDeleteConfirm} buttonsEnabled={confirmModalButtonsEnabled} onClose={() => setShowConfirmModal(false)} message={confirmModalData.message}/>                 
          <Table className="w-full" columns={columns} data={students} loadingContent={loadingData} />          
          <Pagination
            className="ml-auto mt-3"
            pageCount={listData.pageCount}
            currentPage={listData.page - 1}
            handlePageClick={handlePageClick}
          />
        </div>
      )}
    </div>
  );
}
