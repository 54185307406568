import { useState } from "react";
import { useCoursesApi, useTrailsApi } from "../../../../Hooks";
import { ReactComponent as SpinnerIcon } from "../../../../assets/img/icons/spinner.svg";
import { useNavigate } from "react-router-dom";

export default function DownloadCertificateButton({
	className,
	courseId = "",
	rated,
	trailId = "",
}) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const isTrail = trailId !== "";
	const coursesApi = useCoursesApi();
	const trailsApi = useTrailsApi();
	const navigate = useNavigate();
	const text = loading ? "Gerando certificado..." : "Baixar certificado";

	const handleClick = async () => {
		if (loading) return;

		if (!rated) {
			navigate(isTrail ? `/trilhas/${trailId}/concluido` : `/cursos/${courseId}/concluido`);
			return;
		}

		setLoading(true);
		setError(null);
		try {
			await import("@react-pdf/renderer").then(async (reactPdf) => {
				await import(
					"../../../Certificados/CourseCertificate"
				).then(async (certificate) => {
					const certificateInfo = isTrail
						? await trailsApi.getCertificateInfo(
								trailId
						  )
						: await coursesApi.getCertificateInfo(
								courseId
						  );

					const certificateBlob = await reactPdf
						.pdf(
							certificate.default({
								certificateInfo,
							})
						)
						.toBlob();
					const certificateUrl =
						URL.createObjectURL(certificateBlob);
					const anchor = document.createElement("a");

					anchor.href = certificateUrl;
					anchor.download = `Certificado - ${certificateInfo.title}.pdf`;
					anchor.target = "_blank";
					document.body.appendChild(anchor);
					anchor.click();
					document.body.removeChild(anchor);
				});
			});
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	return (
		<>
			<button
				onClick={handleClick}
				className={`${className} flex flex-row font-dosisbold px-2 bg-playmove-yellow rounded-xl py-3 text-xl text-playmove-darkgray border-playmove-orange border-b-[3px] ${
					!loading &&
					"hover:bg-playmove-pink hover:text-white hover:border-playmove-darkpink"
				}`}>
				{loading && (
					<SpinnerIcon className="mr-auto w-8 h-6 my-auto" />
				)}
				<span className="mx-auto">{text}</span>
			</button>
		</>
	);
}
